import React, { useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { createDOBucketName } from "utils/DO-Spaces";
import EmptyState from "components/admin/empty-state/EmptyState";
import Search, { ENUMS as SEARCH_ENUMS } from "components/forms/search/Search";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import SearchIcon from "assets/icons/other/Search.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check/Select.svg";
import { RESERVATION_STEP_TYPES } from "../GuestReservationForm";
import { ReactComponent as ArrowIcon } from "assets/icons/arrows/ArrowRight.svg";
import CTAButton, {
  ENUMS as ENUMS_CTA_BUTTON,
} from "components/buttons/cta-button/CTAButton";

import "./ReservationBusinessSelection.scss";
import Spinner from "../../../elements/spinner/Spinner";

const ReservationBusinessSelection = ({
  businesses,
  setActiveStep,
  setFormData,
  formData,
  isBusinessesLoading,
}) => {
  const { t } = useTranslation();

  const [allBusinessesToDisplay, setAllBusinessesToDisplay] = useState([]);
  const [searchBusinesses, setSearchBusinesses] = useState("");

  const handleSearchBusinesses = (value) => {
    setSearchBusinesses(value);
  };

  const handleSelectBusiness = (business) => {
    if (formData.business?.id === business.id) {
      setFormData({ ...formData, business: null });
    } else {
      setFormData({ ...formData, business: business });
    }
  };

  useEffect(() => {
    filterBusinesses();
  }, [searchBusinesses, businesses]);

  const filterBusinesses = () => {
    let filteredBusinesses = businesses;
    if (searchBusinesses) {
      filteredBusinesses = filteredBusinesses.filter((business) =>
        business.name.toLowerCase().includes(searchBusinesses.toLowerCase())
      );
    }
    setAllBusinessesToDisplay(filteredBusinesses);
  };

  if (isBusinessesLoading) {
    return <Spinner />;
  }

  return (
    <div className="ReservationBusinessSelection">
      <Search
        onChange={(value) => {
          handleSearchBusinesses(value);
        }}
        value={searchBusinesses}
        placeholder={t("reservation.searchRestaurant") + "..."}
        type={SEARCH_ENUMS.types.TYPE_B}
        classname="ReservationBusinessSelectionSearch"
      />

      <div className="ReservationBusinessSelectionAllBusiness">
        {allBusinessesToDisplay?.length > 0 ? (
          allBusinessesToDisplay?.map((business, index) => (
            <div
              key={index}
              onClick={() => handleSelectBusiness(business)}
              className={cx("ReservationBusinessSelectionOneBusiness", {
                SelectedBusiness: formData.business?.id === business.id,
              })}
            >
              <div className="ReservationBusinessSelectionLogoOuter">
                <div className="ReservationBusinessSelectionLogo">
                  <ImageWithPlaceholder
                    imageSource={createDOBucketName(business.images.logo)}
                    placeholder={IMAGE_ITEM_PLACEHOLDER}
                    alt="logo"
                    className="ReservationBusinessSelectionLogo"
                  />
                </div>
              </div>

              <div className="ReservationBusinessSelectionTitle">
                <h6 className="h7 Medium BusinessName">{business.name}</h6>
              </div>

              {formData.business?.id === business.id && (
                <div className="BusinessSelectCheckIcon">
                  <CheckIcon />
                </div>
              )}
            </div>
          ))
        ) : (
          <EmptyState
            description={t("emptyStates.noSearchResults")}
            icon={SearchIcon}
            isAdmin
            className="ReservationSearchEmptyState"
          />
        )}
      </div>

      {formData.business?.id && (
        <CTAButton
          onClick={() => setActiveStep(RESERVATION_STEP_TYPES.reservationForm)}
          name={t("buttons.continue")}
          type={ENUMS_CTA_BUTTON.types.TYPE_N}
          className="ReservationBusinessSelectionButton"
          icon={<ArrowIcon />}
        />
      )}
    </div>
  );
};

ReservationBusinessSelection.propTypes = {
  businesses: PropTypes.array,
  setActiveStep: PropTypes.func,
  setFormData: PropTypes.func,
  formData: PropTypes.object,
  isBusinessesLoading: PropTypes.bool,
};

export default ReservationBusinessSelection;
