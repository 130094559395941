import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { useDispatch } from "react-redux";
import ReactConfetti from "react-confetti";

import useWindowSize from "utils/hooks/useWindowSize";
import { STORE_NAMES } from "utils/constants/redux";
import DashboardHeroSection from "pages/client/dashboard/dashboard-hero-section/DashboardHeroSection";
import GuestInformation, {
  ENUMS,
} from "components/elements/guest-information/GuestInformation";
import {
  calculateAllOrdersDiscountPrice,
  calculateAllOrdersPrice,
  calculateAllOrdersTotalPrice,
  calculateConfirmedOrderItemsCount,
  calculateServiceFee,
} from "utils/general";
import GuestProfiles from "components/elements/guest-profiles/GuestProfiles";
import DashboardOrders from "components/elements/dashboard-orders/DashboardOrders";
import PaymentModal from "components/payment-modal/PaymentModal";
import useOutsideClick from "utils/hooks/useOutsideClick";

// import { updateOrderAsync } from "redux/actions/orderActions";
import IMG_GUEST from "assets/images/placeholder/GuestPlaceholder.png";
import { createDOBucketName } from "utils/DO-Spaces";
import { generateGuestIdSuffix } from "utils/helpers";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import useTimeout from "utils/hooks/useTimeout";
import { ENUMS as ENUMS_CLOSE_BUTTON } from "components/buttons/close-button/CloseButton";

import "./Dashboard.scss";

const Dashboard = () => {
  const { width, height } = useWindowSize().size;
  let [searchParams, setSearchParams] = useSearchParams();
  // const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedGuests, setSelectedGuests] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  // const business = useSelector((state) => state[STORE_NAMES.business].business);
  const [menuItemCount, setMenuItemCount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isReviewRequestSuccessful, setIsReviewRequestSuccessful] =
    useState(null);
  const businessImage = createDOBucketName(
    useSelector((state) => state[STORE_NAMES.business]).business.images.logo
  );
  const qrScanStore = useSelector((state) => state[STORE_NAMES.qrScan]);
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  // const userId = useSelector((state) => state[STORE_NAMES.user].id);
  const tableId = qrScanStore.table.id;
  // const businessId = qrScanStore.businessId;
  const orders = useSelector((state) => state[STORE_NAMES.orders].orders);
  const order = orders?.find((order) => order?.table.id === tableId);
  // const currentGuest = order?.guests.find(
  //   (guest) => guest.person.id === guestId
  // );
  const isOrderEmpty = !order;
  const { serviceFee } = useSelector(
    (state) => state[STORE_NAMES.business].business
  );

  const { business: businessStore } = useSelector(
    (state) => state[STORE_NAMES.business]
  );
  const [openPayment, setOpenPayment, mainElementRef] = useOutsideClick();
  const [showConfetti, setShowConfetti] = useState(
    Boolean(searchParams.get(QUERY_PARAMS.showConfetti))
  );

  const showConfettiWhenConfirmOrder = () => {
    setShowConfetti(false);
    searchParams.delete(QUERY_PARAMS.showConfetti);
    setSearchParams(searchParams);
  };

  useTimeout(showConfettiWhenConfirmOrder, 5000);

  const allOrderers = order
    ? [
        ...(order.guests
          ? order.guests
              .slice()
              .sort((a, b) => a.person.id - b.person.id)
              .map((guest) => ({
                ...guest,
                person: {
                  ...guest.person,
                  id: generateGuestIdSuffix(guest.person.id),
                  originalId: guest.person.id,
                  title: guest.name || t("dashboard.guest.guest"),
                  image: createDOBucketName(guest.profilePic) || IMG_GUEST,
                },
              }))
          : []),
        ...(order.users
          ? order.users
              .slice()
              .sort((a, b) => a.person.id - b.person.id)
              .map((user) => ({
                ...user,
                person: {
                  ...user.person,
                  title: `${user.name}`,
                  image: businessImage || IMG_GUEST,
                },
              }))
          : []),
      ]
    : [];

  // const isPayButtonDisabled =
  //   allOrderers
  //     .flatMap((guest) => guest.orderItems)
  //     .filter((item) => item.isConfirmed).length === 0;

  const promo = order?.priceSummary.promo;

  useEffect(() => {
    isOrderEmpty &&
      navigate(
        `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessStore.id}${ROUTE_NAME.menu}`
      );
  }, [isOrderEmpty]);

  useEffect(() => {
    let guests;
    if (selectedOrders.length > 0 || selectedGuests.length > 0) {
      guests = allOrderers.map((guest) => {
        return {
          ...guest,
          orderItems: guest.orderItems.filter((orderItem) =>
            selectedOrders.includes(orderItem.id)
          ),
        };
      });
    } else {
      guests = allOrderers;
    }
    setMenuItemCount(calculateConfirmedOrderItemsCount(guests));
    const subTotal = calculateAllOrdersPrice(guests);
    const discountPrice = calculateAllOrdersDiscountPrice(guests);
    const serviceFeeTotal = calculateServiceFee(
      subTotal,
      discountPrice,
      serviceFee
    );
    const promoValue =
      promo && !promo.discount.isFixed
        ? (subTotal * promo.discount.rate) / 100
        : 0;
    setTotalPrice(
      calculateAllOrdersTotalPrice(
        subTotal,
        0,
        promoValue,
        serviceFeeTotal,
        discountPrice
      )
    );
  }, [selectedGuests, selectedOrders, promo, order]);

  const handleSelectGuest = (selectedGuestId, isAllSelected) => {
    if (isAllSelected) {
      setSelectedGuests([]);
      return;
    }

    const isSelected = selectedGuests.find(
      (guestId) => guestId === selectedGuestId
    );
    const selectedGuestOrderItems = allOrderers
      .find((user) => user.person.id === selectedGuestId)
      .orderItems.flatMap((orderItem) => orderItem.id);

    if (isSelected) {
      setSelectedGuests((prev) =>
        prev.filter((guestId) => guestId !== selectedGuestId)
      );
      setSelectedOrders((prev) =>
        prev.filter((item) => !selectedGuestOrderItems.includes(item))
      );
    } else {
      setSelectedGuests((prev) => [...prev, selectedGuestId]);
      setSelectedOrders((prev) => [...prev, ...selectedGuestOrderItems]);
    }
  };

  const handlePayClick = () => {
    setOpenPayment(true);
  };

  // TODO remove comments to bring back the Customer Review
  // const handleOnSubmitReview = async (data) => {
  //   const updatedOrder = {
  //     ...order,
  //     guests: [
  //       {
  //         ...currentGuest,
  //         ...data,
  //       },
  //     ],
  //   };
  //   const response = await dispatch(
  //     updateOrderAsync({ businessId, order: updatedOrder, id: order.id })
  //   );
  //   if (response.error) {
  //     setIsReviewRequestSuccessful(false);
  //   } else {
  //     setIsReviewRequestSuccessful(true);
  //   }
  // };

  useEffect(() => {
    if (typeof isReviewRequestSuccessful === "boolean") {
      const timeoutId = setTimeout(() => {
        setIsReviewRequestSuccessful(null);
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [isReviewRequestSuccessful]);

  // const chat = useSelector((state) => state[STORE_NAMES.chat].topics);
  // const currentTopic = chat?.find((topic) => topic.id === order.id);
  // const messages = currentTopic?.messages || [];
  // const authorLastReadTime = currentTopic?.usersLastReadTime.find(
  //   (item) => item.userId === guestId
  // )?.dateTime;

  // const unReadMessages = messages.filter(
  //   (message) =>
  //     message.author.id !== guestId &&
  //     (authorLastReadTime ? authorLastReadTime < message.dateTime : true)
  // );
  return (
    <div className="DashBoard">
      {showConfetti && (
        <ReactConfetti
          width={window.innerWidth <= 1000 ? width : 500}
          height={window.innerHeight <= 1000 ? height : 700}
          recycle={false}
          numberOfPieces={500}
        />
      )}
      <DashboardHeroSection
        menuItemCount={menuItemCount}
        selectedGuests={
          selectedGuests.length > 0
            ? allOrderers.filter((guest) =>
                selectedGuests.includes(guest.person.id)
              )
            : allOrderers
        }
      />
      {order && (
        <>
          <div className="DashBoardInformationSection">
            <div className="DashBoardGuestCount">
              {allOrderers.length > 0 && (
                <h6 className="Medium">
                  {allOrderers.length}{" "}
                  {allOrderers.length === 1
                    ? t("dashboard.guest.guest").toLowerCase()
                    : t("dashboard.guest.guests").toLowerCase()}
                </h6>
              )}
            </div>
            <div className="ProfilesAndOrders">
              <GuestProfiles
                guests={allOrderers}
                onClick={handleSelectGuest}
                selectedGuests={allOrderers.filter((guest) =>
                  selectedGuests.includes(guest.person.id)
                )}
                guestId={generateGuestIdSuffix(guestId)}
                tableName={qrScanStore.table?.name}
              />
              <DashboardOrders
                guests={allOrderers}
                setSelectedGuests={setSelectedGuests}
                selectedOrders={selectedOrders}
                setSelectedOrders={setSelectedOrders}
                selectedGuests={selectedGuests}
              />
            </div>
            {/*<Review*/}
            {/*  guest={currentGuest}*/}
            {/*  onSubmit={handleOnSubmitReview}*/}
            {/*  isReviewRequestSuccessful={isReviewRequestSuccessful}*/}
            {/*/>*/}
          </div>

          <div className="DashBoardGuestInformation">
            <GuestInformation
              totalPrice={totalPrice}
              handlePayClick={handlePayClick}
              menuItemCount={menuItemCount}
              type={ENUMS.types.TYPE_C}
              isPayButtonDisabled={menuItemCount === 0}
              guestCount={allOrderers.length}
              tableName={qrScanStore.table?.name}
            />
            {/*<div className="DashBoardChatButton">*/}
            {/*  <ChatIcon*/}
            {/*    onClick={() =>*/}
            {/*      navigate(*/}
            {/*        `${ROUTE_NAME.client}${ROUTE_NAME.business}/${business?.id}${ROUTE_NAME.chat}`*/}
            {/*      )*/}
            {/*    }*/}
            {/*  />*/}
            {/*  {unReadMessages.length > 0 && (*/}
            {/*    <div className="ChatButtonCount">*/}
            {/*      <h6 className="h7 SemiBold">{unReadMessages.length}</h6>*/}
            {/*    </div>*/}
            {/*  )}*/}
            {/*</div>*/}
          </div>
          <PaymentModal
            mainElementRef={mainElementRef}
            openSlide={openPayment}
            onClose={() => setOpenPayment(false)}
            totalPrice={totalPrice}
            menuItemCount={menuItemCount}
            tableName={qrScanStore.table?.name}
            closeButtonType={ENUMS_CLOSE_BUTTON.types.TYPE_F}
            selectedGuests={
              selectedGuests.length > 0
                ? allOrderers.filter((guest) =>
                    selectedGuests.includes(guest.person.id)
                  )
                : allOrderers
            }
          />
        </>
      )}
    </div>
  );
};

export default Dashboard;
