import React, { useState } from "react";
import PropTypes from "prop-types";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import { createDOBucketName } from "utils/DO-Spaces";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import OptionsSelector from "components/elements/options-selector/OptionsSelector";
import { ErrorMessage } from "@hookform/error-message";
import { Controller, useForm } from "react-hook-form";
import InputControl, {
  ENUMS as ENUMS_INPUT_CONTROL,
} from "components/admin/forms/input-control/InputControl";
import { PhoneInput } from "react-international-phone";
import cx from "classnames";
import { ENUMS } from "components/elements/email-or-phone/EmailOrPhone";
import { useTranslation } from "react-i18next";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import { stringTimeToDateTimeConverter } from "utils/helpers";
import { zodResolver } from "@hookform/resolvers/zod";
import { RESERVATION_STEP_TYPES } from "../GuestReservationForm";
import { RESERVATION_ACTION_TYPE } from "pages/client/reservation/Reservation";
import { ReactComponent as ArrowIcon } from "assets/icons/arrows/ArrowRight.svg";
import CTAButton, {
  ENUMS as ENUMS_CTA_BUTTON,
} from "components/buttons/cta-button/CTAButton";

import "./GuestInfoForm.scss";

export const GUEST_TYPE = {
  me: { type: "me", title: "Me" },
  other: { type: "other", title: "Other" },
};

const GuestInfoForm = ({ setActiveStep, setFormData, formData, type }) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(GUEST_TYPE.me);

  const schemas = useValidationSchema(t);
  const { addGuestReservationModalSchema } = schemas;

  const methods = useForm({
    resolver: zodResolver(addGuestReservationModalSchema),
    criteriaMode: "all",
  });
  const {
    getValues,
    formState: { errors },
    handleSubmit,
    register,
    control,
  } = methods;

  const handleSelectionChange = (value) => {
    setSelectedValue(value);
  };

  const handlePhoneInputChange = (phone, meta, onChange) => {
    if (phone && phone !== ENUMS.plusSign + meta.country.dialCode) {
      onChange(phone);
    } else {
      onChange("");
    }
  };

  const durationOptions = Array.from({ length: 96 }, (_, i) => {
    const minutes = (i + 1) * 15;
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    const hourText = hours > 0 ? `${hours} ${t("common.time.hour")}` : "";
    const minuteText = mins > 0 ? `${mins} ${t("common.time.minute")}` : "";

    const name = hourText
      ? `${hourText}${minuteText ? ` ${minuteText}` : ""}`
      : `${mins.toString().padStart(2, "0")} ${t("common.time.minute")}`;

    return { id: i, name };
  });

  const timeOptions = Array.from({ length: 96 }, (_, i) => {
    const minutes = i * 15;
    let hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    if (hours === 24) hours = 0; // If hours is 24, set to 0

    return {
      id: i,
      name: `${hours > 9 ? hours : "0" + hours}:${
        mins > 9 ? mins : "0" + mins
      }`,
      hours,
      minutes: mins,
    };
  });

  const handleReservationSubmit = (e) => {
    const {
      date,
      guestComment,
      guestName,
      guestPhone,
      guestsCount,
      reservationDuration,
      time,
    } = getValues();

    const convertedReservationDuration = stringTimeToDateTimeConverter(
      reservationDuration,
      t
    );
    const startDateTime = `${date}T${time}:00`;
    const convertedDateTime = new Date(startDateTime);
    let formattedEndDateTime;

    if (convertedReservationDuration.trim().length > 0) {
      const endDateTime = new Date(convertedDateTime);
      const [hours, minutes] = convertedReservationDuration
        .split(":")
        .map(Number);

      endDateTime.setHours(endDateTime.getHours() + hours);
      endDateTime.setMinutes(endDateTime.getMinutes() + minutes);
      const year = endDateTime.getFullYear();
      const month = String(endDateTime.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const day = String(endDateTime.getDate()).padStart(2, "0");
      const hoursFormatted = String(endDateTime.getHours()).padStart(2, "0");
      const minutesFormatted = String(endDateTime.getMinutes()).padStart(
        2,
        "0"
      );
      const secondsFormatted = String(endDateTime.getSeconds()).padStart(
        2,
        "0"
      );

      formattedEndDateTime = `${year}-${month}-${day}T${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`;
    }

    setFormData({
      ...formData,
      guestName,
      guestPhone,
      guestComment,
      guestsCount,
      startDateTime,
      endDateTime: formattedEndDateTime,
      date,
      time,
      reservationDuration,
    });
    setActiveStep(RESERVATION_STEP_TYPES.reservationFinalDetail);
  };

  return (
    <div className="GuestInfoForm">
      <div className="GuestInfoFormTitle">
        <ImageWithPlaceholder
          imageSource={createDOBucketName(formData.business.images.logo)}
          placeholder={IMAGE_ITEM_PLACEHOLDER}
          alt="logo"
        />
        <h5 className="Medium">{formData.business.name}</h5>
      </div>
      <div className="GuestInfoFormContact">
        <h4>{t("reservation.contact")}</h4>
        {/*<OptionsSelector*/}
        {/*  options={Object.values(GUEST_TYPE)}*/}
        {/*  selectedValue={selectedValue}*/}
        {/*  onSelectionChange={handleSelectionChange}*/}
        {/*/>*/}
        <form onSubmit={(e) => e.preventDefault()} className="ContactForm">
          <InputControl
            type="text"
            placeholder={t("inputs.reserveeName")}
            required
            name="guestName"
            labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
            func={{ ...register("guestName") }}
            hasError={errors.guestName}
            defaultValue={formData.guestName}
            error={
              <ErrorMessage
                errors={errors}
                name="guestName"
                render={({ message }) => (
                  <p className="h7 error-message">{message}</p>
                )}
              />
            }
          />
          <div className="GuestInfoFormContactPhoneNumber">
            <Controller
              name="guestPhone"
              control={control}
              defaultValue={formData.guestPhone}
              render={({ field: { onChange, value } }) => (
                <div>
                  <PhoneInput
                    {...register("guestPhone")}
                    value={value}
                    onChange={(phone, meta) => {
                      handlePhoneInputChange(phone, meta, onChange);
                    }}
                    name="guestPhone"
                    placeholder={t("inputs.phoneNumber")}
                    defaultCountry={ENUMS.defaultCountry}
                    forceDialCode
                    hideDropdown
                    className={cx("phone-input", {
                      hasError: errors.phoneNumber,
                    })}
                    inputProps={{
                      autoComplete: "off",
                    }}
                  />
                </div>
              )}
            />
            <div className="EmailOrPhoneSignInMethodError">
              {errors.guestPhone && (
                <ErrorMessage
                  errors={errors}
                  name="guestPhone"
                  render={({ message }) => (
                    <p className="h7 error-message">{message}</p>
                  )}
                />
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="GuestInfoFormDetails">
        <h4>{t("reservation.reservationInfo")}</h4>
        <form onSubmit={(e) => e.preventDefault()}>
          <InputControl
            type="number"
            placeholder={t("inputs.numberOfGuest")}
            required
            defaultValue={formData.guestsCount}
            name="guestsCount"
            labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
            func={{ ...register("guestsCount", { valueAsNumber: true }) }}
            hasError={errors.guestsCount}
            error={
              <ErrorMessage
                errors={errors}
                name="guestsCount"
                render={({ message }) => (
                  <p className="h7 error-message">{message}</p>
                )}
              />
            }
          />
          <div className="ReservationDateAndTime">
            <InputControl
              type="date"
              placeholder={t("inputs.date")}
              required
              name="date"
              labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
              func={{ ...register("date") }}
              hasError={errors.date}
              defaultValue={formData.date}
              min={new Date().toISOString().split("T")[0]}
              error={
                <ErrorMessage
                  errors={errors}
                  name="date"
                  render={({ message }) => (
                    <p className="h7 error-message">{message}</p>
                  )}
                />
              }
            />
            <Controller
              name="time"
              control={control}
              defaultValue={formData.time}
              render={({ field: { value, onChange } }) => (
                <>
                  <Dropdown
                    onChange={(selectedOption) => {
                      onChange(selectedOption.name);
                    }}
                    required
                    value={timeOptions.find((option) => option.name === value)}
                    options={timeOptions}
                    placeholder={t("inputs.time")}
                    func={{
                      ...register("time"),
                    }}
                    name="time"
                    hasError={errors.time}
                    error={
                      <ErrorMessage
                        errors={errors}
                        name="time"
                        render={({ message }) => (
                          <p className="h7 error-message">{message}</p>
                        )}
                      />
                    }
                  />
                </>
              )}
            />
          </div>
          <div className="GuestReservationDuration">
            <Controller
              name="reservationDuration"
              control={control}
              defaultValue={
                formData.reservationDuration.trim().length > 0
                  ? formData.reservationDuration
                  : `1 ${t("common.time.hour")}`
              }
              render={({ field: { value, onChange } }) => (
                <Dropdown
                  onChange={(durationOption) => onChange(durationOption.name)}
                  value={durationOptions.find(
                    (option) => option.name === value
                  )}
                  options={durationOptions}
                  placeholder={t("inputs.duration")}
                  name="reservationDuration"
                />
              )}
            />

            <p>{t("reservation.reservationDurationHelpText")}</p>
          </div>
          <InputControl
            placeholder={t("inputs.comment")}
            defaultValue={formData.guestComment}
            name="guestComment"
            func={{ ...register("guestComment") }}
            noLabelFloating
            textarea
            className="GuestReservationComment"
          />
        </form>
      </div>
      <CTAButton
        onClick={handleSubmit(handleReservationSubmit)}
        name={
          type === RESERVATION_ACTION_TYPE.create
            ? t("buttons.reserve")
            : t("buttons.update")
        }
        type={ENUMS_CTA_BUTTON.types.TYPE_N}
        icon={<ArrowIcon />}
        className="ReservationGuestFormSubmitButton"
      />
    </div>
  );
};

GuestInfoForm.propTypes = {
  setActiveStep: PropTypes.func,
  setFormData: PropTypes.func,
  selectedBusiness: PropTypes.object,
  formData: PropTypes.object,
  type: PropTypes.string,
};

export default GuestInfoForm;
