import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import cx from "classnames";

import ICON_ADD_PHOTO from "assets/icons/other/AddPhotoSmall.svg";
import Photo from "components/admin/cards/photo/Photo";
import Spinner from "components/elements/spinner/Spinner";
import CloseButton, {
  ENUMS as ENUMS_CLOSE_BUTTON,
} from "components/buttons/close-button/CloseButton";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";

import "./AddPhoto.scss";

export const ENUMS = {
  name: "AddPhoto",
  types: {
    TYPE_A: "TYPE_A",
  },
};

const AddPhoto = ({
  onFileSelect,
  images,
  onRemoveImage,
  activeImageSource,
  onClick,
  hasImage,
  allContainerClickable = false,
  type,
}) => {
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const isMultiple = Array.isArray(images);
  const { t } = useTranslation();

  const handleButtonClick = (event) => {
    event.stopPropagation();
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      fileInputRef.current.click();
    }
  };

  const handleFileSelect = async (e) => {
    const selectedFiles = isMultiple ? e.target.files : e.target.files[0];
    if (selectedFiles) {
      try {
        setLoading(true);
        await onFileSelect(selectedFiles);
      } catch (error) {
        toast.error(t("errorMessages.image"));
      }
      setLoading(false);
      e.target.value = "";
    }
  };

  const renderPhotos = () => {
    if (isMultiple) {
      return (
        <div className="AddPhotoUploadedImages">
          {images?.length > 0 && (
            <>
              <h6
                className="h7 Medium AddPhotoTitle"
                onClick={handleButtonClick}
              >
                {t("image.addPhoto")}
              </h6>
              <h6 className="h8 AddPhotoImageFormat">{t("image.formats")}</h6>
            </>
          )}
          {images?.length > 0 || (
            <div className="AddPhotoInner" onClick={handleButtonClick}>
              <img src={ICON_ADD_PHOTO} alt="add" className="AddPhotoIcon" />
              <h6 className="h7 Medium AddPhotoTitle">
                {t("buttons.clickToUpload")}
              </h6>
              <h6 className="h8 AddPhotoImageFormat">{t("image.formats")}</h6>
            </div>
          )}

          <div className="AddPhotoUploadedImagesList">
            {loading && <Spinner />}
            {images.map((image) => (
              <Photo
                key={image}
                activeImage={activeImageSource}
                image={image}
                onClose={() => onRemoveImage(image)}
                onClick={() => {
                  onClick(image);
                }}
              />
            ))}
          </div>
        </div>
      );
    } else if (!isMultiple && images) {
      return (
        <div
          className={cx("AddPhotoUploadedImage", {
            typeA: type === ENUMS.types.TYPE_A,
          })}
        >
          <div className="AddPhotoUploadedImageRemove">
            <CloseButton
              onClick={() => onRemoveImage(images)}
              type={ENUMS_CLOSE_BUTTON.types.TYPE_D}
            />
          </div>
          <div
            className={cx("AddPhotoUploadedImageContainer", {
              typeA: type === ENUMS.types.TYPE_A,
            })}
          >
            <ImageWithPlaceholder
              imageSource={images}
              placeholder={IMAGE_ITEM_PLACEHOLDER}
              alt="uploaded image"
            />
          </div>
        </div>
      );
    } else if (!isMultiple && loading) {
      return (
        <div className="AddPhotoUploadedImage">
          <div className="AddPhotoUploadedImageContainer">
            <Spinner />
          </div>
        </div>
      );
    } else {
      return (
        <div className="AddPhotoInner" onClick={handleButtonClick}>
          {/*{loading && <Spinner />}*/}

          <ImageWithPlaceholder
            imageSource={ICON_ADD_PHOTO}
            placeholder={IMAGE_ITEM_PLACEHOLDER}
            alt="add"
            className="AddPhotoIcon"
          />
          <h6 className="h7 Medium AddPhotoTitle">
            {t("buttons.clickToUpload")}
          </h6>
          <h6 className="h8 AddPhotoImageFormat">{t("image.formats")}</h6>
        </div>
      );
    }
  };
  return (
    <div
      className={cx("AddPhoto", {
        hasImage: hasImage,
        // showBorder:
        //   !showBorder &&
        //   hasImage &&
        //   (type === ENUMS.types.TYPE_A || type === ENUMS.types.TYPE_B),
        AddPhotoClickable:
          (allContainerClickable && images?.length === 0) || images === null,
      })}
      onClick={(event) => {
        if (
          (allContainerClickable && images?.length === 0) ||
          (images === null && loading === false)
        ) {
          handleButtonClick(event);
        }
      }}
    >
      {renderPhotos()}
      <input
        type="file"
        accept="image/*"
        className="AddPhotoFileInput"
        ref={fileInputRef}
        onChange={handleFileSelect}
        multiple={isMultiple}
      />
    </div>
  );
};

AddPhoto.propTypes = {
  /**
   * The function called when a file is selected
   */
  onFileSelect: PropTypes.func.isRequired,

  /**
   * An array of images (if multiple) or an object (if single)
   */
  images: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),

  /**
   * Function to remove an image
   */
  onRemoveImage: PropTypes.func,

  /**
   * Image source of the active photo
   */
  activeImageSource: PropTypes.string,

  /**
   * Function to handle clicks on the component
   */
  onClick: PropTypes.func,

  /**
   * Boolean to check if image is present
   */
  hasImage: PropTypes.bool,

  /**
   * Boolean to enable photo upload for
   */
  allContainerClickable: PropTypes.bool,

  /**
   * Type for selecting image view
   */
  type: PropTypes.string,
};

export default AddPhoto;
