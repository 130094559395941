import React from "react";
import PropTypes from "prop-types";

import BackButton, {
  ENUMS as BACK_BUTTON_ENUMS,
} from "components/buttons/back-button/BackButton";
import HamburgerButton from "components/buttons/hamburger-button/HamburgerButton";

import "./GuestPageHeader.scss";
import { useSearchParams } from "react-router-dom";
import { QUERY_PARAMS } from "../../../utils/constants/routes";

export const ENUMS = {
  name: "GuestPageHeader",
};

const GuestPageHeader = ({ pageTitle, onGoBack, showBurgerMenu = true }) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const hamburgerClickHandler = () => {
    setSearchParams({
      ...searchParams,
      [QUERY_PARAMS.showHamburgerMenu]: true,
    });
  };
  return (
    <div className="GuestPageHeader">
      <BackButton onClick={onGoBack} type={BACK_BUTTON_ENUMS.types.TYPE_C} />
      <h2 className="SemiBold">{pageTitle} </h2>
      <div style={{ width: 44, height: 44 }}>
        {showBurgerMenu && (
          <HamburgerButton onClick={hamburgerClickHandler} hasBackground />
        )}
      </div>
    </div>
  );
};

GuestPageHeader.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  onGoBack: PropTypes.func.isRequired,
  showBurgerMenu: PropTypes.bool,
};

export default GuestPageHeader;
