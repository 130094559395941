import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import InputControl, {
  ENUMS as ENUMS_INPUT_CONTROL,
} from "components/admin/forms/input-control/InputControl";
import { STORE_NAMES } from "utils/constants/redux";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import { ENUMS } from "../elements/email-or-phone/EmailOrPhone";
import cx from "classnames";
import { PhoneInput } from "react-international-phone";
import { ErrorMessage } from "@hookform/error-message";
import { findZoneOfTable } from "utils/helpers";

import "./ReservationForm.scss";

const ReservationForm = ({ data, onDataUpdate, errors, control, register }) => {
  const reservationStatus = useSelector(
    (state) => state[STORE_NAMES.app].enums.reservationStatus
  );

  const { t } = useTranslation();
  const { zones } = useSelector((state) => state[STORE_NAMES.zones]);

  const initData = findZoneOfTable(zones, data.table);

  const initSelectedZone = data.zone
    ? zones.find((z) => z.id === data.zone)
    : initData;

  const initSelectedTable = data?.table
    ? initSelectedZone?.tables.find((t) => t.id === data.table)
    : null;

  const [selectedZone, setSelectedZone] = useState(initSelectedZone);
  const [selectedTable, setSelectedTable] = useState(initSelectedTable);

  const handlePhoneInputChange = (phone, meta, onChange) => {
    if (phone && phone !== ENUMS.plusSign + meta.country.dialCode) {
      onChange(phone);
    } else {
      onChange("");
    }
  };

  const durationOptions = Array.from({ length: 96 }, (_, i) => {
    const minutes = (i + 1) * 15;
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    const hourText = hours > 0 ? `${hours} ${t("common.time.hour")}` : "";
    const minuteText = mins > 0 ? `${mins} ${t("common.time.minute")}` : "";

    const name = hourText
      ? `${hourText}${minuteText ? ` ${minuteText}` : ""}`
      : `${mins.toString().padStart(2, "0")} ${t("common.time.minute")}`;

    return { id: i, name };
  });

  const timeOptions = Array.from({ length: 96 }, (_, i) => {
    const minutes = i * 15;
    let hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    if (hours === 24) hours = 0; // If hours is 24, set to 0

    return {
      id: i,
      name: `${hours > 9 ? hours : "0" + hours}:${
        mins > 9 ? mins : "0" + mins
      }`,
      hours,
      minutes: mins,
    };
  });

  useEffect(() => {
    const selectedTableId = selectedZone ? selectedTable?.id || null : null;

    if (!selectedZone) {
      setSelectedTable(null);
    }

    onDataUpdate((prev) => ({
      ...prev,
      tableId: selectedTableId,
    }));
  }, [selectedTable, selectedZone]);

  return (
    <div className="ReservationForm">
      <form
        className="ReservationEditorForm"
        onSubmit={(e) => e.preventDefault()}
        name="ReservationForm"
      >
        <div className="ReservationEditorContactInfo">
          <h5>{t("reservation.contactInfo")}</h5>
          <div className="ReservationEditorInputFields">
            <InputControl
              name="guestName"
              type="text"
              required
              placeholder={t("reservation.enterName")}
              func={{
                ...register("guestName"),
              }}
              defaultValue={data?.guestName}
              labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
              hasError={errors.guestName}
              error={
                <ErrorMessage
                  errors={errors}
                  name="guestName"
                  render={({ message }) => (
                    <p className="h7 error-message">{message}</p>
                  )}
                />
              }
            />
            <Controller
              name="guestPhone"
              control={control}
              defaultValue={data.guestPhone}
              render={({ field: { onChange, value } }) => (
                <div>
                  <PhoneInput
                    {...register("guestPhone")}
                    value={value}
                    onChange={(phone, meta) => {
                      handlePhoneInputChange(phone, meta, onChange);
                    }}
                    name="guestPhone"
                    placeholder={t("inputs.phoneNumber")}
                    defaultCountry={ENUMS.defaultCountry}
                    forceDialCode
                    hideDropdown
                    className={cx("phone-input", {
                      hasError: errors.phoneNumber,
                    })}
                    inputProps={{
                      autoComplete: "off",
                    }}
                  />
                </div>
              )}
            />
            <div className="EmailOrPhoneSignInMethodError">
              {errors.guestPhone && (
                <ErrorMessage
                  errors={errors}
                  name="guestPhone"
                  render={({ message }) => (
                    <p className="h7 error-message">{message}</p>
                  )}
                />
              )}
            </div>
          </div>
        </div>
        <div className="ReservationEditorBookingInfo">
          <h5>{t("reservation.reservationInfo")}</h5>
          <div className="ReservationEditorInputFields">
            <InputControl
              name="guestsCount"
              type="number"
              placeholder={t("reservation.numberOfGuest")}
              required
              func={{
                ...register("guestsCount", {
                  setValueAs: (value) => (value ? Number(value) : undefined),
                }),
              }}
              defaultValue={data?.guestsCount}
              labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
              hasError={errors.guestsCount}
              error={
                <ErrorMessage
                  errors={errors}
                  name="guestsCount"
                  render={({ message }) => (
                    <p className="h7 error-message">{message}</p>
                  )}
                />
              }
            />
            <div className="ReservationFormGroupedDropdowns">
              <InputControl
                name="reservationDay"
                type="date"
                required
                placeholder={t("reservation.startDate")}
                defaultValue={data?.reservationDay}
                labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
                func={{
                  ...register("reservationDay", {}),
                }}
                hasError={errors.reservationDay}
                error={
                  <ErrorMessage
                    errors={errors}
                    name="reservationDay"
                    render={({ message }) => (
                      <p className="h7 error-message">{message}</p>
                    )}
                  />
                }
              />
              <Controller
                name="time"
                control={control}
                defaultValue={data.time}
                render={({ field: { value, onChange } }) => (
                  <Dropdown
                    onChange={(selectedOption) => {
                      onChange(selectedOption.name);
                    }}
                    required
                    value={timeOptions.find((option) => option.name === value)}
                    options={timeOptions}
                    placeholder={t("reservation.startTime")}
                    func={{
                      ...register("time"),
                    }}
                    name="time"
                    hasError={errors.time}
                    error={
                      <ErrorMessage
                        errors={errors}
                        name="time"
                        render={({ message }) => (
                          <p className="h7 error-message">{message}</p>
                        )}
                      />
                    }
                  />
                )}
              />
            </div>
            <Controller
              name="duration"
              control={control}
              defaultValue={data.duration}
              render={({ field: { value, onChange } }) => (
                <Dropdown
                  onChange={(durationOption) => {
                    onChange(durationOption.name);
                  }}
                  value={durationOptions.find(
                    (option) => option.name === value
                  )}
                  options={durationOptions}
                  placeholder={t("reservation.ReservationDuration")}
                  required
                  name="duration"
                  func={{
                    ...register("duration"),
                  }}
                  hasError={errors.duration}
                  error={
                    <ErrorMessage
                      errors={errors}
                      name="duration"
                      render={({ message }) => (
                        <p className="h7 error-message">{message}</p>
                      )}
                    />
                  }
                />
              )}
            />

            <Controller
              name="zone"
              control={control}
              defaultValue={selectedZone?.id}
              render={({ field: { value, onChange } }) => (
                <Dropdown
                  onChange={(selectedOption) => {
                    setSelectedZone(selectedOption);
                    onChange(selectedOption?.id);
                  }}
                  value={zones.find((option) => option.id === value)}
                  options={zones}
                  placeholder={t("zone.zone")}
                  name="zone"
                  required
                  func={{
                    ...register("zone"),
                    setValueAs: (value) => (value ? value : undefined),
                  }}
                  hasError={errors.zone}
                  error={
                    <ErrorMessage
                      errors={errors}
                      name="zone"
                      render={({ message }) => (
                        <p className="h7 error-message">{message}</p>
                      )}
                    />
                  }
                />
              )}
            />
            <Controller
              name="table"
              control={control}
              disabled={!selectedZone}
              defaultValue={data?.table}
              render={({ field: { value, onChange } }) => (
                <Dropdown
                  onChange={(table) => {
                    onChange(table.id);
                    setSelectedTable(table.name);
                  }}
                  value={selectedZone?.tables.find(
                    (table) => table.id === value
                  )}
                  options={selectedZone?.tables || []}
                  placeholder={t("table.table")}
                  name="table"
                  required
                  func={{
                    ...register("table"),
                  }}
                  hasError={errors.table}
                  error={
                    <ErrorMessage
                      errors={errors}
                      name="table"
                      render={({ message }) => (
                        <p className="h7 error-message">{message}</p>
                      )}
                    />
                  }
                />
              )}
            />
            <Controller
              name="status"
              control={control}
              defaultValue={data.status}
              render={({ field: { value, onChange } }) => (
                <Dropdown
                  onChange={(status) => {
                    onChange(status.id);
                    onDataUpdate((prev) => ({
                      ...prev,
                      status: status.name,
                    }));
                  }}
                  options={Object.values(reservationStatus).map((status) => {
                    return {
                      name: t(`reservation.statuses.${status}`),
                      id: status,
                    };
                  })}
                  value={Object.values(reservationStatus)
                    .map((status) => {
                      return {
                        name: t(`reservation.statuses.${status}`),
                        id: status,
                      };
                    })
                    .find((status) => status.id === value)}
                  required
                  placeholder={t("reservation.reservationStatus")}
                  name="status"
                  func={{
                    ...register("status"),
                  }}
                  hasError={errors.status}
                  error={
                    <ErrorMessage
                      errors={errors}
                      name="status"
                      render={({ message }) => (
                        <p className="h7 error-message">{message}</p>
                      )}
                    />
                  }
                />
              )}
            />
            <InputControl
              name="guestComment"
              type="textarea"
              placeholder={t("inputs.addComment")}
              className="ReservationEditorInputTextarea"
              defaultValue={data?.guestComment}
              textarea
              labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
              func={{
                ...register("guestComment"),
              }}
              hasError={errors.guestComment}
              error={
                <ErrorMessage
                  errors={errors}
                  name="guestComment"
                  render={({ message }) => (
                    <p className="h7 error-message">{message}</p>
                  )}
                />
              }
            />
          </div>
        </div>
      </form>
    </div>
  );
};

ReservationForm.propTypes = {
  data: PropTypes.object,
  onDataUpdate: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
};

export default ReservationForm;
