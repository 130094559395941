import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import cx from "classnames";

import { ORDER_ACTIONS_TYPE } from "utils/constants/data/menu-model";
import AdminOrderList from "pages/admin/admin-pages/admin-order/admin-order-list/AdminOrderList";
import Button from "components/buttons/button/Button";
import {
  commonAsyncErrorMessage,
  PAYMENT_METHODS,
} from "utils/constants/data/base";
import ICON_NOTIFICATIONS from "assets/icons/waiter/Notifications.svg";
import DoneButton from "components/admin/buttons/done-button/DoneButton";
import { calculateAllOrderItemsCount } from "utils/general";
import {
  getGuestsWithUnconfirmedItems,
  handleOnAsyncError,
  mergeGuestData,
} from "utils/helpers";
import isEqual from "lodash/isEqual";
import { updateOrderActionAsync } from "redux/actions/orderActions";
import { useDispatch, useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";
import { setOrReplaceSelectedOrder } from "redux/slices/ordersStore";
import { ReactComponent as IconNotification } from "assets/icons/waiter/newOrder.svg";

import "./AdminOrderNotifications.scss";

const AdminOrderNotifications = ({
  formData,
  setFormData,
  pendingData,
  setPendingData,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const notificationActions = formData.actions.filter((action) => action.value);
  const businessId = useSelector(
    (state) => state[STORE_NAMES.user].user?.business.id
  );
  const billType =
    formData.actions?.find((action) => action.name === ORDER_ACTIONS_TYPE.bill)
      ?.type === PAYMENT_METHODS.CASH
      ? t("payment.cash")
      : t("payment.POSTerminal");

  const handleOnChangeOrderActions = async (actionName) => {
    const findUpdatedAction = formData.actions.find(
      (action) => action.name === actionName
    );

    const updatedAction = {
      ...findUpdatedAction,
      value: !findUpdatedAction.value,
    };

    const actionTypeKey = (actionName) => {
      switch (actionName) {
        case ORDER_ACTIONS_TYPE.bill:
          return "actionBill";
        default:
          return "actionWaiter";
      }
    };

    const actionType = actionTypeKey(updatedAction.name);

    const updatedOrder = {
      action: updatedAction,
      [actionType]: updatedAction.value,
    };
    const response = await dispatch(
      updateOrderActionAsync({
        businessId,
        order: updatedOrder,
        id: formData.id,
      })
    );
    if (response.error) {
      handleOnAsyncError(t(commonAsyncErrorMessage));
    }
    await dispatch(setOrReplaceSelectedOrder([response.payload]));
  };

  const allUnconfirmedItemsCount = calculateAllOrderItemsCount(
    getGuestsWithUnconfirmedItems(formData)
  );

  const handleOnConfirmAllOrderItems = () => {
    const confirmedGuestItems = formData.guests.map((guest) => ({
      ...guest,
      orderItems: guest.orderItems
        .filter(
          (orderItem) =>
            orderItem?.isConfirmed === null && !orderItem?.isPendingList
        )
        .map((orderItem) => {
          return {
            ...orderItem,
            isConfirmed: true,
            isPendingList: true,
          };
        }),
    }));

    const mergedData = mergeGuestData(confirmedGuestItems, pendingData.guests);

    setPendingData({ ...pendingData, guests: mergedData });

    const removeAllGuestItemsFromNotifications = formData.guests.map(
      (guest) => {
        const updatedOrderItems = guest.orderItems.map((orderItem) => {
          if (orderItem?.isConfirmed === null) {
            return {
              ...orderItem,
              isPendingList: true,
            };
          }
          return orderItem;
        });
        return {
          ...guest,
          orderItems: updatedOrderItems,
        };
      }
    );

    setFormData({ ...formData, guests: removeAllGuestItemsFromNotifications });
  };

  const handleOnConfirmOrderItem = (orderItemId, guestId) => {
    const confirmedGuestItems = formData.guests
      .map((guest) => {
        if (guest.person.id === guestId) {
          const updatedOrderItems = guest.orderItems
            .map((orderItem) => {
              if (orderItem.id === orderItemId) {
                return {
                  ...orderItem,
                  isConfirmed: true,
                  isPendingList: true,
                };
              }
            })
            .filter((orderItem) => orderItem !== undefined);
          return {
            ...guest,
            orderItems: [...updatedOrderItems],
          };
        }
        return guest;
      })
      .filter((guest) => guest.person.id === guestId);

    const mergedData = mergeGuestData(confirmedGuestItems, pendingData.guests);

    setPendingData({ ...pendingData, guests: mergedData });

    const removeGuestItemsFromNotifications = formData.guests.map((guest) => {
      if (guest.person.id === guestId) {
        const updatedOrderItems = guest.orderItems.map((orderItem) => {
          if (orderItem.id === orderItemId) {
            return {
              ...orderItem,
              isPendingList: true,
            };
          }
          return orderItem;
        });
        return {
          ...guest,
          orderItems: updatedOrderItems,
        };
      }
      return guest;
    });

    setFormData({ ...formData, guests: removeGuestItemsFromNotifications });
  };

  const handleOnUpdateOrderItem = ({ count, orderItemId, item }) => {
    const key = "guests";
    const data = formData;
    const guestId = data[key].find((guest) => {
      return guest.orderItems.some((orderItem) => orderItem.id === orderItemId);
    }).person.id;
    const updatedItems = data[key].map((currentItem) => {
      if (currentItem.person.id === guestId) {
        return {
          ...currentItem,
          orderItems: currentItem.orderItems.map((orderItem) => {
            if (
              orderItem.id === orderItemId &&
              !isEqual(orderItem.item, item)
            ) {
              return {
                ...orderItem,
                count: count,
                item: item ? item : orderItem.item,
              };
            }
            return orderItem;
          }),
        };
      }
      return currentItem;
    });
    setFormData({ ...data, [key]: updatedItems });
  };

  const handleOnRemoveOrderItem = (orderItemId, guestId) => {
    const removedGuestItems = formData.guests
      .map((guest) => {
        if (guest.person.id === guestId) {
          const updatedOrderItems = guest.orderItems
            .map((orderItem) => {
              if (orderItem.id === orderItemId) {
                return {
                  ...orderItem,
                  isConfirmed: false,
                  isPendingList: true,
                };
              }
            })
            .filter(Boolean);
          return {
            ...guest,
            orderItems: [...updatedOrderItems],
          };
        }
        return guest;
      })
      .filter((guest) => guest.person.id === guestId);

    const mergedData = mergeGuestData(removedGuestItems, pendingData.guests);

    setPendingData({ ...pendingData, guests: mergedData });

    const removeGuestItemsFromFormData = formData.guests.map((guest) => {
      if (guest.person.id === guestId) {
        const updatedOrderItems = guest.orderItems.map((orderItem) => {
          if (orderItem.id === orderItemId) {
            return {
              ...orderItem,
              isPendingList: true,
            };
          }
          return orderItem;
        });
        return {
          ...guest,
          orderItems: updatedOrderItems,
        };
      }
      return guest;
    });
    setFormData({ ...formData, guests: removeGuestItemsFromFormData });
  };

  const CheckStatusGuestsWithUnconfirmedItems =
    getGuestsWithUnconfirmedItems(formData);

  const isNotificationExist =
    notificationActions.length > 0 ||
    getGuestsWithUnconfirmedItems(formData).length > 0;

  return (
    <>
      <div className={cx("AdminOrderNotifications", { isNotificationExist })}>
        <div className="AdminOrderNotificationsHeader">
          <div className="AdminOrderNotificationsTitle">
            <IconNotification className="AdminOrderNotificationsTitleImage" />
            <h4 className="Bold AdminOrderNotificationsTitleName">
              {t("basket.order.notifications")}
            </h4>
          </div>

          {(CheckStatusGuestsWithUnconfirmedItems?.length > 1 ||
            CheckStatusGuestsWithUnconfirmedItems[0]?.orderItems?.length >
              1) && (
            <div className="AdminOrderNotificationsButton">
              <Button
                className={"AdminOrderNotificationCustomButton"}
                text={t("basket.order.acceptAll")}
                onClick={handleOnConfirmAllOrderItems}
                type="button"
              />
            </div>
          )}
          {!isNotificationExist && (
            <h6 className="Medium AdminOrderPendingItemsEmptyText">
              {t("orderItem.empty")}
            </h6>
          )}
        </div>

        {notificationActions.length > 0 && (
          <div
            className={cx("AdminOrderNotificationsActions", {
              hasMarginBottom: allUnconfirmedItemsCount > 0,
            })}
          >
            <h6 className="AdminOrderNotificationsSectionTitle">
              {t("waiter.actions")}
            </h6>
            <div className="AdminOrderNotificationsActionsList">
              {notificationActions.map((action) => (
                <div key={action.id} className="AdminOrderNotificationsAction">
                  <h6 className="Medium">
                    {action.name === ORDER_ACTIONS_TYPE.waiter
                      ? t("waiter.call")
                      : `${t("waiter.bill")} (${billType})`}
                  </h6>
                  <DoneButton
                    onClick={() => handleOnChangeOrderActions(action.name)}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        {allUnconfirmedItemsCount > 0 && (
          <div className="AdminOrderNotificationsNewOrders">
            <h6 className="AdminOrderNotificationsSectionTitle">
              {t("basket.order.newOrder")} ({allUnconfirmedItemsCount})
            </h6>
            <AdminOrderList
              guests={getGuestsWithUnconfirmedItems(formData)}
              onConfirm={handleOnConfirmOrderItem}
              onUpdateOrderItem={handleOnUpdateOrderItem}
              onRemoveOrderItem={handleOnRemoveOrderItem}
            />
          </div>
        )}
      </div>
    </>
  );
};

AdminOrderNotifications.propTypes = {
  /**
   * The form data for order notifications
   */
  formData: PropTypes.object.isRequired,

  /**
   * The form data for order notifications
   */
  pendingData: PropTypes.object.isRequired,

  /**
   * Function to set the form data
   */
  setFormData: PropTypes.func.isRequired,

  /**
   * Function to set the form data
   */
  setPendingData: PropTypes.func.isRequired,
};

export default AdminOrderNotifications;
