import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

// import PrimaryButton, {
//   ENUMS as PRIMARY_BUTTON_ENUMS,
// } from "components/admin/buttons/primary-button/PrimaryButton";
import EditButton, {
  ENUMS as ENUMS_EDIT,
} from "components/admin/buttons/edit-button/EditButton";
import AdminReservationModal from "pages/admin/admin-pages/admin-reservation/admin-reservation-modal/AdminReservationModal";
import useOutsideClick from "utils/hooks/useOutsideClick";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar/calendar.svg";
import { ReactComponent as ClockIcon } from "assets/icons/clock/ClockSmall.svg";
import { ReactComponent as GuestCountIcon } from "assets/icons/reserve/People.svg";
import { ReactComponent as TableIcon } from "assets/icons/reserve/Table.svg";

import "./ReservationDetail.scss";

const ReservationDetail = ({
  status,
  date,
  time,
  since,
  comment,
  guestCount,
  zone,
  tableName,
  guestName,
  guestPhone,
  onEditHandler,
  onConfirmHandler,
  onDenyHandler,
}) => {
  const { t } = useTranslation();

  const [
    openSlideReservation,
    setOpenSlideReservation,
    mainElementRefReservation,
  ] = useOutsideClick();

  return (
    <div className="ReservationDetail">
      <div className="ReservationDetailStatus">
        <h6 className="Medium">
          {t(`reservation.statuses.${status}`)}
          <span>{`| ${since}`}</span>
        </h6>
      </div>
      <div className="ReservationDetailGuestName">
        <h6 className="SemiBold">{guestName}</h6>
        <h6 className="SemiBold">+{guestPhone}</h6>
        <EditButton onClick={onEditHandler} type={ENUMS_EDIT.types.TYPE_E} />
      </div>
      <div className="ReservationDetailGuestInfos">
        <div className="ReservationDetailGuestInfo">
          <div>
            <CalendarIcon className="ReservationInfoDetailCalendarIcon" />
            <span>{date}</span>
          </div>
          <div>
            <GuestCountIcon />
            <span>{guestCount}</span>
          </div>
        </div>
        <div className="ReservationDetailGuestInfo">
          <div>
            <ClockIcon />
            <span>{time}</span>
          </div>
          <div>
            <TableIcon
              style={{ marginLeft: "2" }}
              className="ReservationInfoDetailCalendarIcon"
            />
            {zone && tableName ? (
              <>
                <h6>{zone?.name}</h6>
                <h5>/</h5>
                <h6> {tableName}</h6>
              </>
            ) : (
              <h5>{t("reservation.tableNotFound")}</h5>
            )}
          </div>
        </div>
      </div>
      {comment && (
        <div className="ReservationDetailComment">
          <h6 className="h7 SemiBold">{`${t("reservation.comment")}:`}</h6>
          <h6>{comment}</h6>
        </div>
      )}
      {/*<div className="ReservationDetailActionButtons">*/}
      {/*  <PrimaryButton*/}
      {/*    onClick={onDenyHandler}*/}
      {/*    text={t("buttons.deny")}*/}
      {/*    type={PRIMARY_BUTTON_ENUMS.types.TYPE_C}*/}
      {/*  />*/}
      {/*  <PrimaryButton*/}
      {/*    onClick={onConfirmHandler}*/}
      {/*    text={t("buttons.confirm")}*/}
      {/*    type={PRIMARY_BUTTON_ENUMS.types.TYPE_E}*/}
      {/*  />*/}
      {/*</div>*/}
      <AdminReservationModal
        mainElementRef={mainElementRefReservation}
        openSlide={openSlideReservation}
        setOpenSlide={setOpenSlideReservation}
        title={t("reservation.createReservation")}
      />
    </div>
  );
};
ReservationDetail.propTypes = {
  status: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  since: PropTypes.string.isRequired,
  comment: PropTypes.string,
  guestCount: PropTypes.number.isRequired,
  zone: PropTypes.shape({
    name: PropTypes.string,
  }),
  tableName: PropTypes.string,
  guestName: PropTypes.string.isRequired,
  guestPhone: PropTypes.string.isRequired,
  onEditHandler: PropTypes.func.isRequired,
  onConfirmHandler: PropTypes.func.isRequired,
  onDenyHandler: PropTypes.func.isRequired,
};

export default ReservationDetail;
