import React, { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { DeviceFrameset } from "react-device-frameset";
import { GuestWebsocketContextProvider } from "utils/context-api/GuestWebsocketContext";
import { PARAM_NAME, ROUTE_NAME } from "utils/constants/routes";
import ClientPagesController from "pages/client/ClientPagesController";
import MenuItem from "pages/client/menu-item/MenuItem";
import Basket from "pages/client/basket/Basket";
import Business from "pages/client/business/Business";
import Menu from "pages/client/menu/Menu";
import Dashboard from "pages/client/dashboard/Dashboard";
import useWindowSize from "utils/hooks/useWindowSize";
import AllBusinesses from "pages/client/all-businesses/AllBusinesses";
import GuestChatController from "components/elements/chat/GuestChatController";
import MascotDashboard from "components/mascot/mascot-dashboard/MascotDashboard";
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryFallback from "./common/error-boundary/ErrorBoundary";
import OfflineMenu from "./common/offline-menu/OfflineMenu";
import Reservation from "./client/reservation/Reservation";

const GuestRoutes = () => {
  // TODO Ilgar check where it is needed. Relocate this to better place ??
  useEffect(() => {
    if (window.innerWidth < 1000)
      document.documentElement.style.setProperty(
        "--width-inner-width",
        window.innerWidth
      );
    else {
      document.documentElement.style.setProperty("--width-inner-width", 500);
    }
  }, [window.innerWidth]);

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <GuestWebsocketContextProvider>
        <Routes>
          <Route
            path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}`}
            element={<ClientPagesController />}
          >
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.menu}`}
              element={<Menu />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.reservation}`}
              element={<Reservation />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.menu}${ROUTE_NAME.menuItem}${PARAM_NAME.menuItemId}`}
              element={<MenuItem />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.basket}`}
              element={<Basket />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.info}`}
              element={<Business />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.dashboard}`}
              element={<Dashboard />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.chat}`}
              element={<GuestChatController />}
            />
          </Route>
          <Route path={ROUTE_NAME.allBusinesses} element={<AllBusinesses />} />
          <Route
            path="*"
            element={
              <Navigate
                to={`${ROUTE_NAME.client}${ROUTE_NAME.allBusinesses}`}
              />
            }
          />
          <Route path={ROUTE_NAME.offlineMenu} element={<OfflineMenu />} />
        </Routes>
        <MascotDashboard />
      </GuestWebsocketContextProvider>
    </ErrorBoundary>
  );
};

const AppRouterGuest = () => {
  const { size } = useWindowSize();

  if (size.width >= 1000) {
    return (
      <div className="MockUp">
        <DeviceFrameset device="iPhone X">
          <GuestRoutes />
        </DeviceFrameset>
      </div>
    );
  } else {
    return <GuestRoutes />;
  }
};

export default AppRouterGuest;
