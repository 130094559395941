import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import { ReactComponent as SortArrowDesc } from "assets/icons/admin-dashboard/sortArrowDesc.svg";
import { ReactComponent as SortArrowAsc } from "assets/icons/admin-dashboard/sortArrowAsc.svg";

import "./SortingOptions.scss";

const SORT_TYPE_ENUMS = {
  ASC: "asc",
  DESC: "desc",
};
const SortingOptions = ({ name, sortType, setSortType, title }) => {
  const sortTypeHandler = () => {
    switch (sortType) {
      case null:
        setSortType((prev) => {
          return {
            ...prev,
            [name]: SORT_TYPE_ENUMS.ASC,
          };
        });
        break;
      case SORT_TYPE_ENUMS.ASC:
        setSortType((prev) => {
          return {
            ...prev,
            [name]: SORT_TYPE_ENUMS.DESC,
          };
        });
        break;
      case SORT_TYPE_ENUMS.DESC:
        setSortType((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
        break;
    }
  };

  return (
    <div className={cx("SortingOptions", sortType)} onClick={sortTypeHandler}>
      <h6 className="SemiBold h7">{title} </h6>
      <SortArrowAsc className="SortingOptionsIconAsc" />
      <SortArrowDesc className="SortingOptionsIconDesc" />
    </div>
  );
};

SortingOptions.propTypes = {
  sortType: PropTypes.oneOf([null, "asc", "desc"]),
  setSortType: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default SortingOptions;
