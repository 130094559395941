import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ErrorMessage } from "@hookform/error-message";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import IconButton from "components/buttons/icon-button/IconButton";
import InputControl, {
  ENUMS as ENUMS_INPUT_CONTROL,
} from "components/admin/forms/input-control/InputControl";
import Modal from "components/modal/Modal";
import DeleteButton from "components/buttons/delete-button/DeleteButton";
import QR from "components/admin/cards/QR/QR";
import { ReactComponent as IconClose } from "assets/icons/close/AdminClose.svg";
import PrimaryButton from "components/admin/buttons/primary-button/PrimaryButton";
import { STORE_NAMES } from "utils/constants/redux";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import { createDOBucketName } from "utils/DO-Spaces";
import { QR_TYPES } from "utils/constants/data/base";
import Confirm, {
  ENUMS as ENUMS_CONFIRM,
} from "components/admin/cards/confirm/Confirm";
import useOutsideClick from "utils/hooks/useOutsideClick";
import isEqual from "lodash/isEqual";
import useFormOutsideClickHandler from "utils/hooks/useFormOutsideClickHandler";

import "./AdminTableModal.scss";

const AdminTableModal = ({
  mainElementRef,
  setOpenSlide,
  formData,
  setFormData,
  formDataInitial,
  setFormDataInitial,
  selectedTable,
  activeZone,
  connectedQRCodes,
  zones,
  openSlide,
  onSave,
  onDelete,
  setOutsideClickAction,
  isLoading,
  title,
}) => {
  const isEditable = !!selectedTable;
  const [
    openSlideConfirmCloseModal,
    setOpenSlideConfirmCloseModal,
    mainElementRefConfirmCloseModal,
  ] = useOutsideClick();

  useFormOutsideClickHandler({
    formData,
    formDataInitial,
    setOpenSlide,
    setOpenSlideConfirmCloseModal,
    setOutsideClickAction,
  });
  const menuCurrency = useSelector(
    (state) => state[STORE_NAMES.menu].data?.currency
  );
  const qrs = useSelector((state) => state[STORE_NAMES.qr].qrs);
  const businessUsers = useSelector(
    (state) => state[STORE_NAMES.business]?.users
  );

  const currencyCode = menuCurrency?.code;

  const { t } = useTranslation();
  const tableModalSchema = useValidationSchema(t).tableModalSchema;

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: zodResolver(tableModalSchema),
    criteriaMode: "all",
  });

  const QRCodeOptions =
    qrs
      ?.filter(
        (qrCode) =>
          qrCode.type === QR_TYPES.URL &&
          !connectedQRCodes.some(
            (connectedQRCode) => connectedQRCode.id === qrCode.id
          )
      )
      .concat(connectedQRCodes) || [];

  // const selectedQRWithImage = formData?.QR
  //   ? {
  //       ...formData?.QR,
  //       image: createDOBucketName(formData?.QR?.image),
  //     }
  //   : null;

  const selectedQRWithImage = formData?.QR?.map((qr) => {
    return {
      ...qr,
      image: createDOBucketName(qr?.image),
    };
  });

  useEffect(() => {
    setFormData({
      name: selectedTable?.name || "",
      zone: activeZone || null,
      assignee: selectedTable?.assignee || {},
      QR: selectedTable?.qrLinks || [],
      maxSeat: selectedTable?.maxSeat ? Number(selectedTable?.maxSeat) : null,
      minDeposit: selectedTable?.minDeposit
        ? Number(selectedTable?.minDeposit)
        : null,
    });
    setFormDataInitial({
      name: selectedTable?.name || "",
      zone: activeZone || null,
      assignee: selectedTable?.assignee || {},
      QR: selectedTable?.qrLinks || [],
      maxSeat: selectedTable?.maxSeat ? Number(selectedTable?.maxSeat) : null,
      minDeposit: selectedTable?.minDeposit
        ? Number(selectedTable?.minDeposit)
        : null,
    });
    reset();
  }, [selectedTable, openSlide]);

  const handleOnInputChange = (e) => {
    const { name, value, type } = e.target;
    if (type === "number") {
      setFormData({
        ...formData,
        [name]: value ? parseFloat(value) : null,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleDropdownChange = (option, name) => {
    setFormData({
      ...formData,
      [name]: option ?? {},
    });
  };

  const handleDropdownChangeQr = (option) => {
    const isQrExist = formData.QR.some((qr) => qr.id === option.id);

    let qrArray;
    if (isQrExist) {
      qrArray = formData.QR.filter((qr) => qr.id !== option.id);
    } else {
      qrArray = [...formData.QR, option];
    }

    setFormData({
      ...formData,
      QR: qrArray,
    });
  };

  const handleOnUnlinkQr = (qrId) => {
    const qrArray = formData.QR.filter((qr) => qr.id !== qrId);
    setFormData({
      ...formData,
      QR: qrArray,
    });
  };

  const handleOnSubmit = () => {
    if (isEditable) {
      onSave(formData, selectedTable.id);
    } else {
      onSave(formData);
    }
  };

  const handleOnCancelCloseModal = () => {
    setOpenSlideConfirmCloseModal(false);
  };

  const handleOnConfirmCloseModal = () => {
    setOpenSlide(false);
    setOpenSlideConfirmCloseModal(false);
  };

  const AdminTableModalHeader = (
    <div className="AdminTableModalTitle">
      <h3 className="SemiBold">
        {isEditable
          ? t("buttons.editForModal", { title: title })
          : t("buttons.addForModal", { title: title })}
      </h3>
      <div className="AdminTableModalTitleRight">
        {isEditable && (
          <DeleteButton
            setOpenSlide={setOpenSlide}
            onClick={() => {
              onDelete(selectedTable.id);
            }}
          />
        )}
        <IconButton
          onClick={() => {
            if (!isEqual(formData, formDataInitial)) {
              return setOpenSlideConfirmCloseModal(true);
            }
            setOpenSlide(false);
          }}
          svgComponent={<IconClose />}
        />
      </div>
    </div>
  );

  const AdminTableModalBody = (
    <div className="AdminTableModalBody">
      <form className="AdminTableModalBodyForm">
        <InputControl
          name="name"
          required
          type="text"
          placeholder={t("inputs.tableName")}
          value={formData?.name}
          hasError={errors.name}
          labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
          func={{
            ...register("name", {
              onChange: handleOnInputChange,
            }),
          }}
          error={
            <ErrorMessage
              errors={errors}
              name="name"
              render={({ message }) => (
                <p className="h7 error-message">{message}</p>
              )}
            />
          }
        />
        <InputControl
          name="maxSeat"
          type="number"
          placeholder={t("inputs.seats")}
          value={formData?.maxSeat}
          className="AdminTableModalBodyFormInput"
          hasError={errors.maxSeat}
          labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
          func={{
            ...register("maxSeat", {
              onChange: handleOnInputChange,
              setValueAs: (value) => (value ? Number(value) : undefined),
            }),
          }}
          error={
            <ErrorMessage
              errors={errors}
              name="maxSeat"
              render={({ message }) => (
                <p className="h7 error-message">{message}</p>
              )}
            />
          }
        />
        <InputControl
          name="minDeposit"
          type="number"
          placeholder={t("inputs.minDeposit")}
          value={formData?.minDeposit}
          definitionText={currencyCode}
          className="AdminTableModalBodyFormInput"
          hasError={errors.minDeposit}
          labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
          func={{
            ...register("minDeposit", {
              onChange: handleOnInputChange,
              setValueAs: (value) => (value ? parseFloat(value) : undefined),
            }),
          }}
          error={
            <ErrorMessage
              errors={errors}
              name="minDeposit"
              render={({ message }) => (
                <p className="h7 error-message">{message}</p>
              )}
            />
          }
        />
        <Dropdown
          onChange={(zone) => {
            handleDropdownChange(zone, "zone");
          }}
          name="zone"
          isOptionRequired
          placeholder={t("zone.zone")}
          value={formData?.zone}
          options={zones}
        />
        <Dropdown
          onChange={(assignee) => handleDropdownChange(assignee, "assignee")}
          value={formData?.assignee}
          options={businessUsers}
          placeholder={t("waiter.waiter")}
        />
        <Dropdown
          onChange={(QR) => {
            handleDropdownChangeQr(QR);
          }}
          name="QR"
          placeholder={t("qr.qr")}
          value={formData?.QR}
          options={QRCodeOptions}
          isMultiple
        />
      </form>
      <div className="AdminTableModalBodyQR">
        {selectedQRWithImage?.map((qr) => (
          <QR
            key={qr.id}
            QR={qr}
            table={selectedTable}
            onUnlinkQr={handleOnUnlinkQr}
          />
        ))}
      </div>
      <Confirm
        type={ENUMS_CONFIRM.types.TYPE_C}
        title={t("modal.warningModalTitleUnsavedChanges")}
        mainElementRefConfirm={mainElementRefConfirmCloseModal}
        onCancel={(e) => handleOnCancelCloseModal(e)}
        onConfirm={(e) => handleOnConfirmCloseModal(e)}
        openSlide={openSlideConfirmCloseModal}
        description={t("modal.warningModalDescription")}
      />
    </div>
  );

  const AdminTableModalFooter = (
    <PrimaryButton
      onClick={handleSubmit(handleOnSubmit)}
      text={t("buttons.save")}
      isLoading={isLoading}
    />
  );

  return (
    <Modal
      header={AdminTableModalHeader}
      body={AdminTableModalBody}
      footer={AdminTableModalFooter}
      mainElementRef={mainElementRef}
      openSlide={openSlide}
    />
  );
};

AdminTableModal.propTypes = {
  /**
   * The ref for the main element of the modal
   */
  mainElementRef: PropTypes.object,

  /**
   * The function called to set the open slide state
   */
  setOpenSlide: PropTypes.func,

  /**
   * The form data for the table
   */
  /**
   * The title to display
   */
  title: PropTypes.string.isRequired,
  formData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    zone: PropTypes.object,
    assignee: PropTypes.object.isRequired,
    QR: PropTypes.object,
    maxSeat: PropTypes.any,
    minDeposit: PropTypes.any,
  }),

  /**
   * The function called to set the form data for the table
   */
  setFormData: PropTypes.func.isRequired,

  /**
   * The form data for the table
   */
  formDataInitial: PropTypes.shape({
    name: PropTypes.string.isRequired,
    zone: PropTypes.object,
    assignee: PropTypes.object.isRequired,
    QR: PropTypes.object,
    maxSeat: PropTypes.any,
    minDeposit: PropTypes.any,
  }),

  /**
   * The function called to set the form data for the table
   */
  setFormDataInitial: PropTypes.func,

  /**
   * The currently selected table
   */
  selectedTable: PropTypes.object,

  /**
   * The list of available zones
   */
  zones: PropTypes.array.isRequired,

  /**
   * Flag to determine if the modal is open
   */
  openSlide: PropTypes.bool,

  /**
   * The function called when the form is submitted to save the data.
   */
  onSave: PropTypes.func,

  /**
   * The function called to delete the item.
   */
  onDelete: PropTypes.func,

  /**
   * The active zone
   */
  activeZone: PropTypes.object,

  /**
   * The list of connected QR codes
   */
  connectedQRCodes: PropTypes.array,

  /**
   * The function called to set the outside click action
   */
  setOutsideClickAction: PropTypes.func,

  /**
   * The loading state of the form
   * */
  isLoading: PropTypes.bool,
};

export default AdminTableModal;
