import { commonAsyncErrorMessage } from "utils/constants/data/base";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const API_ERROR_STATUS_CODES = {
  601: {
    field: "pinCode",
    messageKey: "alreadyExists",
  },
  602: {
    field: "email",
    messageKey: "notExists",
  },
  603: {
    field: "phoneNumber",
    messageKey: "notExists",
  },
  604: {
    field: "password",
    messageKey: "incorrect",
  },
  606: {
    field: "email",
    messageKey: "alreadyExists",
  },
  607: {
    field: "phoneNumber",
    messageKey: "alreadyExists",
  },
  608: {
    field: "email",
    messageKey: "ExistActiveOrder",
  },
  609: {
    field: "menuItem",
    messageKey: "ExistActiveOrder",
    code: 609,
  },
};

const useAPIErrorStatusCodeHelper = () => {
  const { t } = useTranslation();

  const getErrorDataByStatusCode = (serverErrorResponseBody) => {
    const statusCode = serverErrorResponseBody?.statusCode;
    const errorInfo = API_ERROR_STATUS_CODES[statusCode];

    if (errorInfo) {
      const { field, messageKey } = errorInfo;

      return {
        field,
        errorMessage: t(`errorMessages.input.${field}.${messageKey}`),
      };
    }

    return null;
  };

  const showFallbackError = (serverErrorResponseBody) => {
    const serverErrorMessage = serverErrorResponseBody?.message;
    toast.error(serverErrorMessage || t(commonAsyncErrorMessage));
  };

  const handleAPIErrorMessage = (serverErrorResponseBody) => {
    const errorData = getErrorDataByStatusCode(serverErrorResponseBody);
    if (errorData) {
      return errorData;
    }

    showFallbackError(serverErrorResponseBody);
    return null;
  };

  return {
    getErrorDataByStatusCode,
    showFallbackError,
    handleAPIErrorMessage,
  };
};

export default useAPIErrorStatusCodeHelper;
