export const STORE_NAMES = {
  app: "App",
  user: "User",
  guest: "Guest",
  menu: "Menu",
  basket: "Basket",
  orders: "Orders",
  business: "Business",
  zones: "Zones",
  qr: "Qr",
  qrScan: "QR Scan",
  inventory: "Inventory",
  kitchens: "Kitchens",
  ui: "UI",
  chat: "Chat",
  redux: "redux",
  reservation: "Reservation",
};

export const STORE_VALUES = {
  isLoading: "isLoading",
  thunkAPIStates: "thunkAPIStates",
};
