import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AdminOrderDetail from "../admin-order-detail/AdminOrderDetail";

import AdminOrderMenu from "../admin-order-menu/AdminOrderMenu";
import useOutsideClick from "utils/hooks/useOutsideClick";
import { handleOnAsyncError, handleOnAsyncSuccess } from "utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";
import { ORDER_ACTIONS_TYPE } from "utils/constants/data/menu-model";
import {
  getOrderByIdAsync,
  updateOrderAssigneeAsync,
  updateOrderAsync,
} from "redux/actions/orderActions";
import { commonAsyncErrorMessage } from "utils/constants/data/base";
import { useTranslation } from "react-i18next";
import AdminOrderTabContent from "../admin-order-tab-content/AdminOrderTabContent";
import AdminOrderActions from "../admin-order-actions/AdminOrderActions";
import AdminOrderDetailSidebar from "../admin-order-detail-sidebar/AdminOrderDetailSidebar";
import useGetLoggedBusinessUser from "utils/hooks/useGetLoggedBusinessUser";
import { useSearchParams } from "react-router-dom";
import { QUERY_PARAMS } from "utils/constants/routes";
import Spinner from "components/elements/spinner/Spinner";
import {
  setNullSelectedOrder,
  setOrReplaceSelectedOrder,
} from "redux/slices/ordersStore";
import CloseButton, {
  ENUMS as ENUMS_CLOSE_BUTTON,
} from "components/buttons/close-button/CloseButton";

import "./AdminOrderPage.scss";

export const MENU_TABS_ENUMS = {
  name: "AddMenuItem",
  tabType: {
    HOME: "HOME",
    MENU: "MENU",
    CHAT: "CHAT",
    // PRINTS: t("common.home"),
  },
};

const AdminOrderPage = ({
  setOpenSlide,
  isNewOrderCreated,
  setIsNewOrderCreated,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const businessId = useSelector(
    (state) => state[STORE_NAMES.user].user?.business.id
  );
  const {
    selectedOrder,
    thunkAPIStates: { getSelectedOrder },
  } = useSelector((state) => state[STORE_NAMES.orders]);
  const orderEnums = useSelector(
      (state) => state[STORE_NAMES.app].enums
  )?.orderStatus;

  const [activeTab, setActiveTab] = useState(MENU_TABS_ENUMS.tabType.HOME);
  const [selectedOrderItem, setSelectedOrderItem] = useState(null);
  const [isEnableToAddOrder, setIsEnableToAddOrder] = useState(false);
  const [isOrderLoading, setIsOrderLoading] = useState(true);
  const [isOrderUpdate, setIsOrderUpdate] = useState(false);

  const chat = useSelector((state) => state[STORE_NAMES.chat].topics);

  const selectedOrderId = parseInt(
    searchParams.get(QUERY_PARAMS.selectedOrder)
  );

  const user = useGetLoggedBusinessUser();

  const [
    openSlideOrderItem,
    setOpenSlideOrderItem,
    mainElementRefOrderItem,
    ,
    ,
    setOutsideClickAction,
  ] = useOutsideClick();

  const initialData = {
    id: null,
    table: null,
    assignee: null,
    status: null,
    createDate: "",
    priceSummary: null,
    guests: [],
    users: [],
    actions: [
      {
        name: ORDER_ACTIONS_TYPE.bill,
        value: false,
        type: null,
      },
      {
        name: ORDER_ACTIONS_TYPE.waiter,
        value: false,
      },
    ],
  };
  const [formData, setFormData] = useState(initialData);
  const [pendingData, setPendingData] = useState(initialData);

  const setUsers = (original, updated) => {
    let updatedUsers = [...updated];

    if (original.length > updated.length) {
      const updatedUserIds = updated.map((user) => user.id);
      original.forEach((originalUser) => {
        if (!updatedUserIds.includes(originalUser.id)) {
          updatedUsers.push(originalUser);
        }
      });
    }

    return updatedUsers;
  };

  const setGuests = (original, updated) => {
    let updatedGuests = [...updated];
    if (original.length > updated.length) {
      const updatedGuestIds = updated.map((guest) => guest.person.id);
      original.forEach((originalGuest) => {
        if (!updatedGuestIds.includes(originalGuest.person.id)) {
          updatedGuests.push(originalGuest);
        }
      });
    }
    updatedGuests = updatedGuests.map((guest) => {
      const guestOrderItemIds = guest.orderItems.map(
        (orderItem) => orderItem.id
      );

      original.forEach((originalGuest) => {
        if (originalGuest.person.id === guest.person.id) {
          originalGuest.orderItems.forEach((originalOrderItem) => {
            if (!guestOrderItemIds.includes(originalOrderItem.id)) {
              const update = guest.orderItems
                .map((item) => {
                  if (item.id === originalOrderItem.id) {
                    if (!item.isArchived) {
                      return {
                        ...item,
                        isPendingList:
                          item.isConfirmed === null
                            ? originalOrderItem.isPendingList
                            : false,
                        count:
                          item.isConfirmed === null
                            ? originalOrderItem.count
                            : item.count,
                      };
                    }
                  }
                  return item;
                })
                .filter((item) => item !== undefined);
              guest = {
                ...guest,
                // orderMessage: originalGuest.orderMessage,
                orderItems: update,
              };
            } else {
              const update = guest.orderItems.map((item) => {
                if (item.id === originalOrderItem.id) {
                  return {
                    ...item,
                    isPendingList:
                      item.isConfirmed === null
                        ? originalOrderItem.isPendingList
                        : false,
                    count:
                      item.isConfirmed === null
                        ? originalOrderItem.count
                        : item.count,
                  };
                }
                return item;
              });
              guest = {
                ...guest,
                // orderMessage: originalGuest.orderMessage,
                orderItems: update,
              };
            }
          });
        }
      });

      return guest;
    });
    return updatedGuests;
  };

  useEffect(() => {
    const fetchOrder = async () => {
      if (!isNewOrderCreated) {
        try {
          await dispatch(
            getOrderByIdAsync({
              businessId,
              orderId: selectedOrderId,
            })
          ).unwrap();
        } catch (error) {
          console.error("Failed to fetch order:", error);
        } finally {
          setIsOrderLoading(false);
          setIsNewOrderCreated(false);
        }
      } else {
        setIsNewOrderCreated(false);
        setIsOrderLoading(false);
      }
    };
    fetchOrder();
  }, []);

  const handleOnUpdateOrderAssignee = async (order) => {
    const response = await dispatch(
      updateOrderAssigneeAsync({ businessId, order, id: selectedOrder.id })
    );
    if (response.error) {
      handleOnAsyncErrorForOrder();
    } else {
      handleOnAsyncSuccess(t("toastMessages.success.updateOrder"), () => {
        setPendingData({
          ...pendingData,
          assignee: response.payload.assignee,
        });
      });
    }
  };

  useEffect(() => {
    if (!isOrderLoading && !getSelectedOrder && !isOrderUpdate) {
      if (selectedOrder) {
        setFormData({
          id: selectedOrder.id,
          table: selectedOrder.table,
          assignee: selectedOrder.assignee,
          status: selectedOrder.status,
          createDate: selectedOrder.createDate,
          priceSummary: selectedOrder.priceSummary,
          guests: setGuests(formData.guests, selectedOrder.guests),
          users: setUsers(formData.users, selectedOrder.users),
          actions: selectedOrder.actions,
        });
        setPendingData({
          id: selectedOrder.id,
          table: selectedOrder.table,
          assignee: selectedOrder.assignee,
          status: selectedOrder.status,
          createDate: selectedOrder.createDate,
          priceSummary: selectedOrder.priceSummary,
          guests: pendingData.guests,
          users: pendingData.users,
          actions: selectedOrder.actions,
        });
        if (!selectedOrder.assignee) {
          handleOnUpdateOrderAssignee({ assigneeId: user.id });
        }
      } else {
        setFormData(initialData);
        setPendingData(initialData);
        searchParams.delete(QUERY_PARAMS.selectedOrder);
        setSearchParams(searchParams);
      }
    }
  }, [selectedOrder, isOrderLoading, getSelectedOrder, isOrderUpdate]);

  const handleOnAsyncErrorForOrder = (errorMessage) => {
    handleOnAsyncError(errorMessage || t(commonAsyncErrorMessage));
  };

  const handleOnUpdateOrder = async (order, onSuccess) => {
    setIsOrderUpdate(true);
    const response = await dispatch(
      updateOrderAsync({ businessId, order, id: selectedOrder.id })
    );
    if (response.error) {
      handleOnAsyncErrorForOrder();
    } else {
      dispatch(setOrReplaceSelectedOrder([response.payload]));
      const successMessage =
        order?.status === orderEnums.finished
          ? t("toastMessages.success.finishedOrder")
          : t("toastMessages.success.updateOrder");

      await handleOnAsyncSuccess(successMessage, async () => {
        if (typeof onSuccess === "function") {
          await onSuccess();
        }
      });
    }
    setIsOrderUpdate(false);
  };
  const handleCloseOrderTab = () => {
    const from = searchParams.get(QUERY_PARAMS.from);
    if (from) {
      setSearchParams(from);
    } else {
      searchParams.delete(QUERY_PARAMS.selectedOrder);
      setSearchParams(searchParams);
    }
    dispatch(setNullSelectedOrder());
  };

  const currentTopic = chat?.find((topic) => topic.id === selectedOrder?.id);
  const lastMessageTime =
    currentTopic?.messages[currentTopic.messages.length - 1]?.dateTime;
  const lastReadTime = currentTopic?.usersLastReadTime.find(
    (item) => item.userId === user.id
  )?.dateTime;
  let hasNewMessage;
  if (lastMessageTime && lastReadTime) {
    hasNewMessage = lastReadTime < lastMessageTime;
  } else if (lastMessageTime && !lastReadTime) {
    hasNewMessage = true;
  }

  return (
    <div className="AdminOrderPage">
      <div className="AdminOrderPageHeader">
        <div className="AdminOrderPageHeaderTabs">
          <AdminOrderMenu
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            hasNewMessage={hasNewMessage}
          />
        </div>
        {/*<div className="AdminOrderPageHeaderReturnButton">*/}
        {/*  <TextIconButton*/}
        {/*    text="Tables"*/}
        {/*    svgComponent={<IconTables />}*/}
        {/*    onClick={handleCloseOrderTab}*/}
        {/*    className="AdminOrderPageHeaderTableIcon"*/}
        {/*  />*/}
        {/*</div>*/}
        <div className="AdminOrderPageHeaderActionButtons">
          <AdminOrderActions
            setOpenSlide={setOpenSlide}
            selectedOrder={selectedOrder}
            pendingData={pendingData}
            handleOnUpdateOrder={handleOnUpdateOrder}
            formData={formData}
          />
        </div>
        <div className="AdminOrderPageHeaderReturnButton">
          <CloseButton
            text="Tables"
            onClick={handleCloseOrderTab}
            type={ENUMS_CLOSE_BUTTON.types.TYPE_E}
          />
          {/*<TextIconButton*/}
          {/*  text="Tables"*/}
          {/*  svgComponent={<IconTables />}*/}
          {/*  onClick={() => {*/}
          {/*    setOpenSlide(false);*/}
          {/*  }}*/}
          {/*  className="AdminOrderPageHeaderTableIcon"*/}
          {/*/>*/}
        </div>
      </div>
      {isOrderLoading ? (
        <Spinner className="SpinnerHeight" />
      ) : (
        <div className="AdminOrderPageContent">
          <AdminOrderTabContent
            handleOnUpdateOrder={handleOnUpdateOrder}
            formData={formData}
            activeTabs={activeTab}
            setSelectedOrderItem={setSelectedOrderItem}
            setIsEnableToAddOrder={setIsEnableToAddOrder}
            pendingData={pendingData}
            setPendingData={setPendingData}
            selectedOrder={selectedOrder}
            setOpenSlideOrderItem={setOpenSlideOrderItem}
          />
          <div className="AdminOrderDetail">
            <AdminOrderDetail
              selectedOrder={selectedOrder}
              selectedOrderItem={selectedOrderItem}
              setSelectedOrderItem={setSelectedOrderItem}
              isEnableToAddOrder={isEnableToAddOrder}
              setIsEnableToAddOrder={setIsEnableToAddOrder}
              pendingData={pendingData}
              setPendingData={setPendingData}
              formData={formData}
              setFormData={setFormData}
              handleOnUpdateOrder={handleOnUpdateOrder}
              openSlideOrderItem={openSlideOrderItem}
              setOpenSlideOrderItem={setOpenSlideOrderItem}
              mainElementRefOrderItem={mainElementRefOrderItem}
              setOutsideClickAction={setOutsideClickAction}
            />
          </div>
        </div>
      )}
      <AdminOrderDetailSidebar
        selectedOrder={selectedOrder}
        selectedOrderItem={selectedOrderItem}
        setSelectedOrderItem={setSelectedOrderItem}
        isEnableToAddOrder={isEnableToAddOrder}
        setIsEnableToAddOrder={setIsEnableToAddOrder}
        pendingData={pendingData}
        setPendingData={setPendingData}
        formData={formData}
        setFormData={setFormData}
        handleOnUpdateOrder={handleOnUpdateOrder}
        openSlideOrderItem={openSlideOrderItem}
        setOpenSlideOrderItem={setOpenSlideOrderItem}
        mainElementRefOrderItem={mainElementRefOrderItem}
      />
    </div>
  );
};

AdminOrderPage.propTypes = {
  /**
   * The function to set the open slide state of the modal
   */
  setOpenSlide: PropTypes.func,

  /**
   * This state help us to decide whether to call the getOrderById API.
   */
  isNewOrderCreated: PropTypes.bool,

  setIsNewOrderCreated: PropTypes.func,
};

export default AdminOrderPage;
