import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import "react-calendar/dist/Calendar.css";
import Modal from "components/modal/Modal";
import DeleteButton from "components/buttons/delete-button/DeleteButton";
import ReservationForm from "components/reservation-form/ReservationForm";
import FooterButtons from "components/admin/elements/footer-buttons/FooterButtons";
import CloseButton, {
  ENUMS as ENUMS_CLOSE_BUTTON,
} from "components/buttons/close-button/CloseButton";
import { EDITOR_TYPE } from "pages/admin/data/constants/general";
import PrimaryButton, {
  ENUMS as ENUMS_PRIMARY_BUTTON,
} from "components/admin/buttons/primary-button/PrimaryButton";
import useAsync from "utils/hooks/useAsync";
import {
  createReservationAdmin,
  deleteReservation,
  updateReservationAdmin,
} from "utils/api/services/reservation";
import { STORE_NAMES } from "utils/constants/redux";
import {
  commonAsyncErrorMessage,
  RESERVATION_STATUS,
} from "utils/constants/data/base";
import {
  formatDateForReservationDatePicker,
  formatTimeForReservationDatePicker,
  getDestructuredReservation,
  handleOnAsyncError,
  handleOnAsyncSuccess,
} from "utils/helpers";
import { deleteReservationRedux } from "redux/slices/reservationStore";
import { getAllReservationsAsync } from "redux/actions/reservationAction";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import Confirm, {
  ENUMS as ENUMS_CONFIRM,
} from "components/admin/cards/confirm/Confirm";
import useOutsideClick from "utils/hooks/useOutsideClick";
import isEqual from "lodash/isEqual";
import useFormOutsideClickHandler from "utils/hooks/useFormOutsideClickHandler";

import "./AdminReservationModal.scss";

const ReservationTimer = ({ requestDateTime }) => {
  const [elapsedTime, setElapsedTime] = useState("");

  useEffect(() => {
    const updateTimer = () => {
      const requestDate = new Date(requestDateTime);
      const currentTime = new Date();
      const timeDifference = currentTime - requestDate;

      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      setElapsedTime(
        `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
      );
    };

    const timerInterval = setInterval(updateTimer, 1000);

    return () => clearInterval(timerInterval);
  }, [requestDateTime]);

  return <span>{elapsedTime}</span>;
};

const AdminReservationModal = ({
  mainElementRef,
  openSlide,
  setOpenSlide,
  title,
  formType,
  data,
  selectedDate,
  setOutsideClickAction,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [
    openSlideConfirmCloseModal,
    setOpenSlideConfirmCloseModal,
    mainElementRefConfirmCloseModal,
  ] = useOutsideClick();

  const schemas = useValidationSchema(t);
  const { adminReservationModalSchema } = schemas;
  const methods = useForm({
    resolver: zodResolver(adminReservationModalSchema),
    criteriaMode: "all",
  });
  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
    getValues,
    reset,
    watch,
  } = methods;
  const createInitialFormData = () => {
    if (data) {
      const startDate = new Date(data.startDateTime);
      let duration = "";

      if (data.endDateTime) {
        const endDate = new Date(data.endDateTime);
        const timeDifference = endDate - startDate;
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const minutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );

        const hourText = hours > 0 ? `${hours} ${t("common.time.hour")}` : "";
        const minuteText =
          minutes > 0 ? `${minutes} ${t("common.time.minute")}` : "";

        duration = hourText
          ? `${hourText}${minuteText ? ` ${minuteText}` : ""}`
          : `${minutes.toString().padStart(2, "0")} ${t("common.time.minute")}`;
      }

      return {
        guestName: data.guestName,
        guestPhone: "+" + data.guestPhone,
        guestComment: data.guestComment,
        guestsCount: data.guestsCount,
        reservationDay: formatDateForReservationDatePicker(startDate),
        time: formatTimeForReservationDatePicker(startDate),
        duration,
        table: data?.table?.id || null,
        zone: data?.table?.zone || undefined,
        status: data.status,
        id: data.id,
      };
    } else {
      return {
        guestName: "",
        guestPhone: "",
        guestComment: "",
        guestsCount: 1,
        reservationDay: selectedDate
          ? formatDateForReservationDatePicker(selectedDate)
          : null,
        time: "",
        table: null,
        zone: undefined,
        duration: "",
        status: "",
        id: null,
      };
    }
  };

  useEffect(() => {
    const initialFormData = createInitialFormData();
    setFormData(initialFormData);
    reset(initialFormData);
  }, [data, reset, selectedDate, openSlide]);

  const [formData, setFormData] = useState(null);

  const handleCloseModal = () => {
    const editedFormData = { ...watch(), table: getValues().table || null };
    if (!isEqual(editedFormData, createInitialFormData())) {
      return setOpenSlideConfirmCloseModal(true);
    }
    setOpenSlide(false);
    reset();
  };

  useFormOutsideClickHandler({
    formData: { ...watch(), table: getValues().table || null },
    formDataInitial: createInitialFormData(),
    setOpenSlide,
    setOpenSlideConfirmCloseModal,
    setOutsideClickAction,
  });

  const businessId = useSelector(
    (state) => state[STORE_NAMES.business].business?.id
  );

  const { execute: executeDeleteReservation } = useAsync(deleteReservation, {
    onError: () => handleOnAsyncErrorForReservation(),
    onSuccess: ({ data }) => {
      setFormData(createInitialFormData());
      setOpenSlide(false);
      dispatch(deleteReservationRedux(data));
      handleOnAsyncSuccessForReservation(
        t("toastMessages.success.deleteReservation")
      );
    },
  });
  const { execute: executeUpdateReservation } = useAsync(
    updateReservationAdmin,
    {
      onError: () => handleOnAsyncErrorForReservation(),
      onSuccess: () => {
        setFormData(createInitialFormData());
        setOpenSlide(false);
        dispatch(
          getAllReservationsAsync({
            businessId,
            startDate: selectedDate.toLocaleDateString(),
          })
        );
        handleOnAsyncSuccessForReservation(
          t("toastMessages.success.updateReservation")
        );
      },
    }
  );

  const {
    execute: executeCreateReservation,
    loading: isCreateReservationLoading,
  } = useAsync(createReservationAdmin, {
    onError: () => handleOnAsyncErrorForReservation(),
    onSuccess: () => {
      reset(createInitialFormData());
      handleCloseModal();
      handleOnAsyncSuccessForReservation(
        t("toastMessages.success.createReservation")
      );
    },
  });

  const formatTimeToOptionsFormat = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Pad single-digit hours and minutes with leading zeros
    return `${hours < 10 ? "0" : ""}${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes}`;
  };

  const getHourDifference = (date1, date2) => {
    const differenceInMilliseconds = Math.abs(
      new Date(date2) - new Date(date1)
    );
    const differenceInHours = differenceInMilliseconds / 3600000;
    return differenceInHours;
  };

  // useEffect(() => {
  //   reset();
  // }, [data, openSlide]);

  const isFormValid = () => {
    return !!formData?.guestName && !!formData?.guestPhone;
  };

  const handleOnAsyncErrorForReservation = (errorMessage) => {
    handleOnAsyncError(errorMessage || t(commonAsyncErrorMessage));
  };

  const handleOnAsyncSuccessForReservation = (successMessage) => {
    handleOnAsyncSuccess(successMessage);
  };

  const handleOnClickCancel = async (reservation) => {
    await executeDeleteReservation(businessId, reservation.id);
    handleCloseModal();
  };

  const handleOnClickConfirm = async (reservation) => {
    const destructuredReservation = getDestructuredReservation(formData, t);
    const updatedReservation = {
      ...destructuredReservation,
      status: RESERVATION_STATUS.CONFIRMED,
    };
    await executeUpdateReservation(
      businessId,
      updatedReservation,
      reservation.id
    );

    handleCloseModal();
  };

  const handleOnDeleteReservation = async (reservation) => {
    await executeDeleteReservation(businessId, reservation.id);
    handleCloseModal();
  };

  const handleOnSaveReservation = async () => {
    const updatedReservation = getDestructuredReservation(getValues(), t);
    if (formData?.id) {
      await executeUpdateReservation(businessId, [
        { id: formData.id, ...updatedReservation, guestId: formData.guestId },
      ]);
    } else {
      await executeCreateReservation(businessId, updatedReservation);
    }
  };
  const handleOnCancelCloseModal = () => {
    setOpenSlideConfirmCloseModal(false);
  };

  const handleOnConfirmCloseModal = () => {
    setOpenSlide(false);
    setOpenSlideConfirmCloseModal(false);
    reset();
  };

  const reservationModalHead = (
    <div className="ReservationEditorModalHeader">
      <div className="ReservationEditorModalHeaderTitle">
        <h3 className="SemiBold">
          {formType === EDITOR_TYPE.EDIT
            ? t("buttons.editForModal", { title: title })
            : t("buttons.addForModal", { title: title })}
        </h3>
        {formType === EDITOR_TYPE.CONFIRM && (
          <ReservationTimer requestDateTime={formData?.requestDateTime} />
        )}
      </div>

      <div className="ReservationEditorModalControls">
        {formType === EDITOR_TYPE.EDIT && (
          <DeleteButton onClick={() => handleOnDeleteReservation(formData)} />
        )}
        <CloseButton
          onClick={handleCloseModal}
          type={ENUMS_CLOSE_BUTTON.types.TYPE_F}
        />
      </div>
    </div>
  );

  const reservationModalBody = (
    <div className="ReservationEditorModalBody">
      <ReservationForm
        onDataUpdate={setFormData}
        data={formData}
        errors={errors}
        control={control}
        register={register}
      />
      <Confirm
        type={ENUMS_CONFIRM.types.TYPE_C}
        title={t("modal.warningModalTitleUnsavedChanges")}
        mainElementRefConfirm={mainElementRefConfirmCloseModal}
        onCancel={(e) => handleOnCancelCloseModal(e)}
        onConfirm={(e) => handleOnConfirmCloseModal(e)}
        openSlide={openSlideConfirmCloseModal}
        description={t("modal.warningModalDescription")}
      />
    </div>
  );
  const AdminReservationEditorModalFooter = (
    <>
      {formType === EDITOR_TYPE.CONFIRM ? (
        <FooterButtons
          onCancel={() => handleOnClickCancel(formData)}
          onConfirm={() => handleOnClickConfirm(formData)}
          cancelButtonText={t("buttons.deny")}
          confirmButtonText={t("buttons.confirm")}
          cancelButtonType={ENUMS_PRIMARY_BUTTON.types.TYPE_D}
        />
      ) : (
        <PrimaryButton
          onClick={handleSubmit(handleOnSaveReservation)}
          text={t("buttons.save")}
          disabled={!isFormValid() || isCreateReservationLoading}
          isLoading={isCreateReservationLoading}
        />
      )}
    </>
  );

  return (
    <div className="AdminReservationModal">
      <Modal
        header={reservationModalHead}
        body={reservationModalBody}
        footer={AdminReservationEditorModalFooter}
        mainElementRef={mainElementRef}
        openSlide={openSlide}
      />
    </div>
  );
};

AdminReservationModal.propTypes = {
  /**
   * The ref for the modal
   */
  mainElementRef: PropTypes.object,
  /**
   * An object containing data for the reservation.
   */
  data: PropTypes.object,
  /**
   * Flag to determine if the modal is open
   */
  openSlide: PropTypes.bool,
  /**
   * The function called to set the open slide state
   */
  setOpenSlide: PropTypes.func,
  /**
   * The title of the modal
   */
  title: PropTypes.string,
  /**
   * The type of the editor
   */
  formType: PropTypes.oneOf(Object.values(EDITOR_TYPE)),

  selectedDate: PropTypes.instanceOf(Date),

  /**
   * The function called to set the outside click action
   */
  setOutsideClickAction: PropTypes.func,
};

ReservationTimer.propTypes = {
  requestDateTime: PropTypes.string,
};

export default AdminReservationModal;
