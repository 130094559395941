import { createSlice } from "@reduxjs/toolkit";
import {
  createGuestAsync,
  updateGuestInfoAsync,
} from "redux/actions/guestAction";

import { STORE_NAMES } from "utils/constants/redux";
import { MENU_ORDER_MODES } from "utils/constants/data/base";

const initialState = {
  id: null,
  name: null,
  profilePicture: null,
  phoneNumber: null,
  selectedMenuView: null,
  menuFilters: {
    priceRange: { min: 0, max: 0 },
    selectedTags: [],
  },
  menuOrderMode: MENU_ORDER_MODES.EAT_IN,
  thunkAPIStates: {
    updateGuestInfo: false,
  },
};

export const guestStore = createSlice({
  name: STORE_NAMES.guest,
  initialState: initialState,
  reducers: {
    setGuestId: (state, action) => {
      const id = action.payload;
      return { ...state, id };
    },
    setGuestInfo: (state, action) => {
      const { id, profilePicture, name, phoneNumber } = action.payload;
      return { ...state, id, profilePicture, name, phoneNumber };
    },
    selectMenuView: (state, action) => {
      const menuViewId = action.payload;
      return { ...state, selectedMenuView: menuViewId };
    },
    setFilterOptions: (state, action) => {
      const { selectedTags, priceRange } = action.payload;
      return {
        ...state,
        menuFilters: { ...state.menuFilters, selectedTags, priceRange },
      };
    },
    resetMenuFilters: (state) => {
      return { ...state, menuFilters: { ...initialState.menuFilters } };
    },
    setMenuOrderMode: (state, action) => {
      const updatedMenuOrderMode = action.payload;
      return { ...state, menuOrderMode: updatedMenuOrderMode };
    },
    resetGuestStore: () => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createGuestAsync.fulfilled, (state, { payload }) => {
      const { id, name, phoneNumber, profilePic: profilePicture } = payload;
      return {
        ...state,
        id,
        name,
        phoneNumber,
        profilePicture,
      };
    });
    builder.addCase(updateGuestInfoAsync.pending, (state) => {
      return {
        ...state,
        thunkAPIStates: { ...state.thunkAPIStates, updateGuestInfo: true },
      };
    });
    builder.addCase(updateGuestInfoAsync.rejected, (state) => {
      return {
        ...state,
        thunkAPIStates: { ...state.thunkAPIStates, updateGuestInfo: false },
      };
    });
    builder.addCase(updateGuestInfoAsync.fulfilled, (state, { payload }) => {
      const { name, phoneNumber, profilePic: profilePicture } = payload;
      return {
        ...state,
        name,
        phoneNumber,
        profilePicture,
        thunkAPIStates: { ...state.thunkAPIStates, updateGuestInfo: false },
      };
    });
  },
});
export const {
  setGuestId,
  setGuestInfo,
  resetGuestStore,
  selectMenuView,
  setFilterOptions,
  resetMenuFilters,
  setMenuOrderMode,
} = guestStore.actions;

export default guestStore.reducer;
