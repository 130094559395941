import { resetBasketStore } from "redux/slices/basketStore";
import { resetMenuStore } from "redux/slices/menuStore";
import { resetGuestStore } from "redux/slices/guestStore";
import { resetOrdersStore } from "redux/slices/ordersStore";
import { resetUserStore } from "redux/slices/userStore";
import { resetBusinessStore } from "redux/slices/businessStore";
import { resetZoneStore } from "redux/slices/zoneStore";
import { resetQrScanStore } from "redux/slices/qrScanStore";
import { setIsAdminDashboardPinLocked } from "redux/slices/appStore";
import { resetInventoryStore } from "redux/slices/inventoryStore";
import { resetChatStore } from "redux/slices/chatStore";
import { resetQrStore } from "redux/slices/qrStore";
import {
  checkValidDateRange,
  getBasketModificationsWithDefaultValues,
} from "./helpers";
import { createDOBucketName } from "./DO-Spaces";
import { sortBy } from "lodash";

export const resetAllReduxStores = (dispatch) => {
  // dispatch(resetGuestStore());
  // dispatch(resetAppStore());
  dispatch(setIsAdminDashboardPinLocked(false));
  dispatch(resetQrScanStore());
  dispatch(resetBusinessStore());
  dispatch(resetMenuStore());
  dispatch(resetOrdersStore());
  dispatch(resetUserStore());
  dispatch(resetZoneStore());
  dispatch(resetInventoryStore());
  dispatch(resetBasketStore());
  dispatch(resetChatStore());
  dispatch(resetQrStore());
};

export const resetReduxStoresForGuest = async (dispatch, qrId) => {
  qrId && dispatch(resetOrdersStore());
  qrId && dispatch(resetQrScanStore());
  qrId && dispatch(resetChatStore());
  dispatch(resetBusinessStore());
  dispatch(resetMenuStore());
};

export const resetReduxStoresWhenBusinessChange = async (dispatch) => {
  dispatch(resetBasketStore());
  dispatch(resetChatStore());
};

export const resetReduxStoresIfOrderNotFound = async (dispatch) => {
  dispatch(resetOrdersStore());
  dispatch(resetQrScanStore());
  dispatch(resetChatStore());
};

export const resetReduxStoresForAdmin = (dispatch) => {
  dispatch(resetMenuStore());
  dispatch(resetOrdersStore());
  dispatch(resetZoneStore());
  dispatch(resetInventoryStore());
  dispatch(resetQrStore());
};

export const calculateDiscountPrice = (item) => {
  const discount =
    item.rate &&
    item.rate.isEnabled &&
    (item.rate.isFixed
      ? item.rate.amount
      : (item.priceSell * item.rate.amount) / 100);
  return discount !== 0 && discount !== false ? -discount : null;
};

export const calculateModificationsPrice = (modifications) => {
  return modifications.reduce((acc, modification) => {
    const modificationSum = modification.options.reduce((accOption, option) => {
      const optionTotal = option.priceSell * option?.count;
      return accOption + optionTotal;
    }, 0);

    return acc + modificationSum;
  }, 0);
};

export const calculateModificationsPriceCost = (modifications) => {
  return modifications.reduce((acc, modification) => {
    const modificationSum = modification.options.reduce((accOption, option) => {
      const optionTotal =
        (option?.priceCost ?? option?.priceSell) * option?.count;
      return accOption + optionTotal;
    }, 0);

    return acc + modificationSum;
  }, 0);
};

export const calculateMenuItemPrice = (menuItem) => {
  return (
    menuItem.priceSell + calculateModificationsPrice(menuItem.modifications)
  );
};

export const calculateMenuItemPriceCost = (menuItem) => {
  // const discountPrice = calculateMenuItemPriceBySchedule(menuItem);
  return (
    (menuItem?.priceCost ?? menuItem?.priceSell) +
    calculateModificationsPriceCost(menuItem.modifications)
  );
};

export const findItemById = (menuItemId, menu) => {
  if (!menu) {
    return null;
  }
  for (const category of menu.categories) {
    for (const menuItem of category.menuItems) {
      if (menuItem.id.toString() === menuItemId.toString()) {
        return { ...menuItem };
      }
    }
  }
  return null;
};

export const calculateAllOrderItemsCount = (guests) => {
  return guests.reduce((totalOrdersCount, guest) => {
    const guestOrderItemsCount = guest.orderItems.reduce(
      (guestSum, orderItem) => guestSum + orderItem.count,
      0
    );
    return totalOrdersCount + guestOrderItemsCount;
  }, 0);
};

export const calculateConfirmedOrderItemsCount = (guests) => {
  return guests.reduce((totalOrdersCount, guest) => {
    const guestOrderItemsCount = guest?.orderItems?.reduce(
      (guestSum, orderItem) => {
        if (orderItem.isConfirmed !== false) {
          return guestSum + orderItem.count;
        }
        return guestSum;
      },
      0
    );
    return totalOrdersCount + guestOrderItemsCount;
  }, 0);
};

export const calculateAllOrdersPrice = (guests) => {
  return guests.reduce((sum, guest) => {
    const guestOrderItemsPrice = guest.orderItems.reduce((sum, orderItem) => {
      if (orderItem.isConfirmed || orderItem.isConfirmed !== false) {
        return sum + orderItem.count * calculateMenuItemPrice(orderItem.item);
      }
      return sum;
    }, 0);
    return sum + guestOrderItemsPrice;
  }, 0);
};

export const sortCategoriesAndMenuItems = (categories, sortByField) => {
  return sortBy(categories, sortByField).map((category) => ({
    ...category,
    menuItems: sortBy(category.menuItems, sortByField).filter(
      (menuItem) => !menuItem.isArchived
    ),
  }));
};

export const calculateCategoriesOrder = (categories) => {
  return categories.map((category, index) => {
    return { ...category, placeInTheList: index + 1 };
  });
};

export const reorderItems = (listOfItems, startIndex, endIndex) => {
  const result = Array.from(listOfItems);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const areItemPositionsDifferent = (initial, current) => {
  if (initial.length !== current.length) return true;

  for (let i = 0; i < initial.length; i++) {
    if (
      initial[i].id !== current[i].id ||
      initial[i].position !== current[i].position
    ) {
      return true;
    }
  }

  return false;
};

export const calculateAllOrdersPriceCost = (guests) => {
  return guests.reduce((sum, guest) => {
    const guestOrderItemsPrice = guest.orderItems.reduce((sum, orderItem) => {
      if (orderItem.isConfirmed || orderItem.isConfirmed !== false) {
        return (
          sum + orderItem.count * calculateMenuItemPriceCost(orderItem.item)
        );
      }
      return sum;
    }, 0);
    return sum + guestOrderItemsPrice;
  }, 0);
};

export const calculateAllOrdersDiscountPrice = (guests) => {
  return guests.reduce((sum, guest) => {
    const guestOrderItemsPrice = guest.orderItems.reduce((sum, orderItem) => {
      if (orderItem.isConfirmed || orderItem.isConfirmed !== false) {
        return (
          sum -
          orderItem.count * calculateMenuItemPriceBySchedule(orderItem.item)
        );
      }
      return sum;
    }, 0);
    return sum - guestOrderItemsPrice;
  }, 0);
};

export const filterBySchedule = (item) => {
  const today = new Date();
  const todayDayOfWeek = today
    .toLocaleDateString("en-US", { weekday: "long" })
    .toLowerCase();

  const from = new Date(item.schedule?.from);
  const to = new Date(item.schedule?.to);
  const isValidDateRange = checkValidDateRange(from, to, today);

  const todaySchedule =
    item.schedule?.weekdays && item.schedule?.weekdays[todayDayOfWeek];

  const isWorkingToday =
    todaySchedule &&
    todaySchedule.isWorking &&
    today >= new Date(today.toDateString() + " " + todaySchedule.from) &&
    today <= new Date(today.toDateString() + " " + todaySchedule.to);

  return isWorkingToday && isValidDateRange;
};

export const filterMenuItemByIsPublishedAndSchedule = (
  menuItems,
  categoryId,
  displayDataByLanguage,
  searchValue
) => {
  const menuItemsByIsPublished = menuItems
    .filter(
      (menuItem) =>
        menuItem.isPublished &&
        !menuItem.isArchived &&
        (displayDataByLanguage
          ? displayDataByLanguage(menuItem.name)
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          : true)
    )
    .map((menuItem) => ({
      ...menuItem,
      coverImageSrc: createDOBucketName(menuItem.coverImageSrc),
      otherImagesSrc: menuItem.otherImagesSrc?.map((otherImageSrc) =>
        createDOBucketName(otherImageSrc)
      ),
      categoryId: categoryId,
    }));

  return menuItemsByIsPublished.filter((menuItem) => {
    if (menuItem?.schedule?.isActive !== true) {
      return true;
    } else {
      const isValidDateRange = filterBySchedule(menuItem);

      if (isValidDateRange) {
        return true;
      }
    }

    return false;
  });
};

export const filterCategoryByIsPublishedAndSchedule = (categories) => {
  if (!categories) {
    return null;
  }
  const categoriesByIsPublished = categories.filter(
    (category) =>
      category.isPublished &&
      !category.isArchived &&
      category.menuItems.some((menuItem) => menuItem.isPublished)
  );

  return categoriesByIsPublished.filter((category) => {
    if (category?.schedule?.isActive !== true) {
      const menuItemOfCategoryBySchedule =
        filterMenuItemByIsPublishedAndSchedule(category.menuItems, category.id);

      return menuItemOfCategoryBySchedule.length > 0;
    } else {
      const isValidDateRange = filterBySchedule(category);

      if (isValidDateRange) {
        const menuItemOfCategoryBySchedule =
          filterMenuItemByIsPublishedAndSchedule(
            category.menuItems,
            category.id
          );

        return menuItemOfCategoryBySchedule.length > 0;
      }
    }

    return false;
  });
};

export const calculateMenuItemPriceBySchedule = (menuItem) => {
  if (menuItem.rate?.schedule?.isActive !== true) {
    return calculateDiscountPrice(menuItem);
  } else {
    const isValidDateRange = filterBySchedule(menuItem.rate);

    if (isValidDateRange) {
      return calculateDiscountPrice(menuItem);
    }

    return null;
  }
};

export const calculateMenuItemFinalPrice = (
  discountPrice,
  modificationPrice
) => {
  return discountPrice !== null
    ? discountPrice + modificationPrice
    : discountPrice;
};

export const calculateItemDefaultModificationPrice = (menuItem) => {
  const modifications = getBasketModificationsWithDefaultValues(
    menuItem.modifications
  );
  return calculateModificationsPrice(modifications);
};

export const calculateItemPriceWithDefaultModificationPrice = (menuItem) => {
  const modifications = getBasketModificationsWithDefaultValues(
    menuItem.modifications
  );
  return menuItem.priceSell + calculateModificationsPrice(modifications);
};

export const calculateItemPriceWithSelectedModification = (menuItem) => {
  return (
    menuItem.priceSell + calculateModificationsPrice(menuItem.modifications)
  );
};

export const calculateTax = (subtotal, tax) => {
  return (subtotal * tax) / 100;
};

export const calculateServiceFee = (subtotal, discountPrice, serviceFee) => {
  return ((subtotal + discountPrice) * serviceFee) / 100;
};

export const calculatePromoDiscount = (subtotal, promoCode) => {
  return promoCode
    ? promoCode.discount.isFixed
      ? promoCode.discount.rate
      : (subtotal * promoCode.discount.rate) / 100
    : 0;
};

export const calculateAllOrdersTotalPrice = (
  subtotal,
  extraFees,
  promoCode,
  serviceFee,
  discountPrice
) => {
  // const tax = calculateTax(subtotal, extraFees.tax);
  const promoDiscount = calculatePromoDiscount(subtotal, promoCode);
  if (subtotal === 0) return 0;
  return subtotal + serviceFee - promoDiscount + discountPrice;
};

export const findTableById = (id, tables) => {
  return tables.find((table) => table.id === id);
};

export const findTableByReservationId = (reservationId, tables) => {
  for (const table of tables) {
    const reservation = table.reservations.find(
      (res) => res.id === reservationId
    );
    if (reservation) {
      return table;
    }
  }
  return null;
};
