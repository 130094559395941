import React from "react";
import { Route, Routes } from "react-router-dom";
import useAuthCheck from "utils/hooks/useAuthCheck";

import AdminMenuController from "pages/admin/admin-pages/admin-menu/admin-menu-controller/AdminMenuController";
import AdminBusiness from "pages/admin/admin-pages/admin-business/admin-business-controller/AdminBusiness";
import AdminBusinessSettings from "pages/admin/admin-pages/admin-business/admin-business-settings/AdminBusinessSettings";
import AdminQrTableMap from "pages/admin/admin-pages/admin-qr-table-map/AdminQrTableMap";
import AdminQr from "pages/admin/admin-pages/admin-qr/AdminQr";
import AdminUser from "pages/admin/admin-pages/admin-user/AdminUser";
import AdminReservation from "pages/admin/admin-pages/admin-reservation/AdminReservation";
import AdminMenuSettings from "pages/admin/admin-pages/admin-menu/admin-menu-settings/AdminMenuSettings";
import AdminOrder from "pages/admin/admin-pages/admin-order/AdminOrder";
import AdminOrderSettings from "pages/admin/admin-pages/admin-order-settings/AdminOrderSettings";
import SuperAdmin from "pages/admin/admin-pages/super-admin/super-admin-business/SuperAdmin";
import AdminKitchen from "pages/admin/admin-pages/admin-kitchen/AdminKitchen";
import AdminKitchenSettings from "pages/admin/admin-pages/admin-kitchen-settings/AdminKitchenSettings";
import AdminInventory from "pages/admin/admin-pages/admin-inventory/AdminInventory";
import AdminDashboard from "pages/admin/admin-pages/admin-dashboard/AdminDashboard";
import AdminDashAllOrders from "pages/admin/admin-pages/admin-dashboard/admin-dash-all-orders/AdminDashAllOrders";
import AdminDashAllMenus from "pages/admin/admin-pages/admin-dashboard/admin-dash-all-menus/AdminDashAllMenus";
import AdminDashSalesStats from "pages/admin/admin-pages/admin-dashboard/admin-dash-sales-stats/AdminDashSalesStats";
import AdminDashWeeklyStats from "pages/admin/admin-pages/admin-dashboard/admin-dash-weekly-stats/AdminDashWeeklyStats";
import AdminDashOrderStats from "pages/admin/admin-pages/admin-dashboard/admin-dash-order-stats/AdminDashOrderStats";
import AdminDashMenuStats from "pages/admin/admin-pages/admin-dashboard/admin-dash-menu-stats/AdminDashMenuStats";
//import AdminDashStaffStats from "pages/admin/admin-pages/admin-dashboard/admin-dash-staff-stats/AdminDashStaffStats";
import SuperAdminInfra from "pages/admin/admin-pages/super-admin/super-admin-infra/SuperAdminInfra";
import SuperAdminGuests from "pages/admin/admin-pages/super-admin/super-admin-guests/SuperAdminGuests";
import AdminDetailedChat from "pages/admin/admin-pages/admin-order/admin-detailed-chat/AdminDetailedChat";

import { ROUTE_NAME } from "utils/constants/routes";

import "./AdminBodyController.scss";

const AdminBodyController = () => {
  const isAuthenticated = useAuthCheck();

  if (isAuthenticated) {
    return (
      <div className="AdminBodyController">
        <Routes>
          <Route path={ROUTE_NAME.adminStatistics} element={<AdminDashboard />}>
            <Route
              path={ROUTE_NAME.adminDashboardSalesStatistics}
              element={<AdminDashSalesStats />}
            />
            <Route
              path={ROUTE_NAME.adminDashboardOrderStatistics}
              element={<AdminDashOrderStats />}
            />
            <Route
              path={ROUTE_NAME.adminDashboardAllOrders}
              element={<AdminDashAllOrders />}
            />
            <Route
              path={ROUTE_NAME.adminDashboardMenuStatistics}
              element={<AdminDashMenuStats />}
            />
            <Route
              path={ROUTE_NAME.adminDashboardAllMenus}
              element={<AdminDashAllMenus />}
            />
            <Route
              path={ROUTE_NAME.adminDashboardWeeklyStatistics}
              element={<AdminDashWeeklyStats />}
            />
            {/*<Route*/}
            {/*  path={ROUTE_NAME.adminDashboardStaff}*/}
            {/*  element={<AdminDashStaffStats />}*/}
            {/*/>*/}
          </Route>

          <Route
            path={ROUTE_NAME.adminMenuCreate}
            element={<AdminMenuController />}
          />
          <Route
            path={ROUTE_NAME.adminMenuSettings}
            element={<AdminMenuSettings />}
          />
          <Route
            path={ROUTE_NAME.adminBusinessGeneral}
            element={<AdminBusiness />}
          />
          <Route
            path={ROUTE_NAME.adminBusinessSettings}
            element={<AdminBusinessSettings />}
          />
          <Route path={ROUTE_NAME.adminUser} element={<AdminUser />} />
          <Route path={ROUTE_NAME.adminTable} element={<AdminQrTableMap />} />
          <Route path={ROUTE_NAME.adminQR} element={<AdminQr />} />
          <Route
            path={ROUTE_NAME.adminReservation}
            element={<AdminReservation />}
          />
          <Route
            path={ROUTE_NAME.adminOrderDashboard}
            element={<AdminOrder />}
          />
          <Route
            path={ROUTE_NAME.adminOrderChat}
            element={<AdminDetailedChat />}
          />
          <Route
            path={ROUTE_NAME.adminOrderSettings}
            element={<AdminOrderSettings />}
          />
          {/*<Route*/}
          {/*  path={ROUTE_NAME.adminKitchenDashboard}*/}
          {/*  element={<AdminKitchen />}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  path={ROUTE_NAME.adminKitchenSettings}*/}
          {/*  element={<AdminKitchenSettings />}*/}
          {/*/>*/}
          <Route path={ROUTE_NAME.super} element={<SuperAdmin />} />
          <Route path={ROUTE_NAME.superInfra} element={<SuperAdminInfra />} />
          <Route path={ROUTE_NAME.superGuests} element={<SuperAdminGuests />} />
          <Route
            path={ROUTE_NAME.adminInventory}
            element={<AdminInventory />}
          />
        </Routes>
      </div>
    );
  }
};

export default AdminBodyController;
