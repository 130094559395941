import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import { createDOBucketName } from "utils/DO-Spaces";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";
import { ReactComponent as PendingIcon } from "assets/icons/reserve/pending.svg";
import { ReactComponent as ConfirmedIcon } from "assets/icons/reserve/confirmed.svg";
import { ReactComponent as DeniedIcon } from "assets/icons/reserve/denied.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar/calendar.svg";
import { ReactComponent as ClockIcon } from "assets/icons/clock/ClockCircle.svg";
import { ReactComponent as GuestCountIcon } from "assets/icons/reserve/People.svg";
import { ReactComponent as TableIcon } from "assets/icons/reserve/element-plus.svg";
import { ReactComponent as ArrowIcon } from "assets/icons/arrows/ArrowDown.svg";
import { ReactComponent as MenuIcon } from "assets/icons/reserve/menu.svg";
import { ReactComponent as PhoneIcon } from "assets/icons/reserve/phone.svg";
import { ReactComponent as LocationIcon } from "assets/icons/reserve/location.svg";
import {
  formatDateForReservationDatePicker,
  formatReservationDate,
  formatReservationTimeRange,
  formatTimeForReservationDatePicker,
} from "utils/helpers";
import { ROUTE_NAME } from "utils/constants/routes";
import { useNavigate } from "react-router-dom";
import ThreeDots from "components/admin/elements/three-dots/ThreeDots";
import Confirm from "components/admin/cards/confirm/Confirm";
import useOutsideClick from "utils/hooks/useOutsideClick";
import useAsync from "utils/hooks/useAsync";
import { deleteReservation } from "utils/api/services/reservation";
import Spinner from "components/elements/spinner/Spinner";
import { RESERVATION_PAGE_TYPE } from "pages/client/reservation/Reservation";

import "./ReservationInfo.scss";

const ReservationInfo = ({
  reservation,
  setGuestReservations,
  setShowAddReservationForm,
  setFormData,
}) => {
  const { t } = useTranslation();
  const [showReservationDetail, setShowReservationDetail] = useState(true);
  const [reservationDetailsHeight, setReservationDetailsHeight] = useState(0);
  const OrderDetailRef = useRef(null);

  const {
    execute: executeDeleteReservation,
    loading: isDeleteReservationLoading,
  } = useAsync(deleteReservation, {
    onSuccess: (res) => {
      setGuestReservations((prev) => {
        return prev.filter((reservation) => reservation.id !== res.data.id);
      });
    },
  });

  const [openSlideConfirm, setOpenSlideConfirm, mainElementRefConfirm] =
    useOutsideClick();

  useEffect(() => {
    if (OrderDetailRef.current) {
      const height = OrderDetailRef.current.clientHeight;
      setReservationDetailsHeight(height);
    }
  }, []);

  const { enums } = useSelector((state) => state[STORE_NAMES.app]);
  const { zones } = useSelector((state) => state[STORE_NAMES.zones]);
  const navigate = useNavigate();
  const { business } = useSelector((state) => state[STORE_NAMES.business]);

  const threeDotsOptions = [
    {
      name: t("reservation.delete"),
      action: () => setOpenSlideConfirm(true),
    },
  ];
  if (reservation.status === enums.reservationStatus.pending) {
    const treeDotsUpdateOption = {
      name: t("reservation.update"),
      action: () => {
        const startDate = new Date(reservation.startDateTime);
        let duration = "";

        if (reservation.endDateTime) {
          const endDate = new Date(reservation.endDateTime);
          const timeDifference = endDate - startDate;
          const hours = Math.floor(timeDifference / (1000 * 60 * 60));
          const minutes = Math.floor(
            (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
          );

          const hourText = hours > 0 ? `${hours} ${t("common.time.hour")}` : "";
          const minuteText =
            minutes > 0 ? `${minutes} ${t("common.time.minute")}` : "";

          duration = hourText
            ? `${hourText}${minuteText ? ` ${minuteText}` : ""}`
            : `${minutes.toString().padStart(2, "0")} ${t(
                "common.time.minute"
              )}`;
        }

        setFormData((prev) => {
          return {
            ...prev,
            reservationId: reservation.id,
            guestName: reservation.guestName,
            guestPhone: reservation.guestPhone,
            guestComment: reservation.guestComment,
            guestsCount: reservation.guestsCount,
            startDateTime: reservation.startDateTime,
            endDateTime: reservation.endDateTime,
            date: formatDateForReservationDatePicker(startDate),
            time: formatTimeForReservationDatePicker(startDate),
            reservationDuration: duration,
            business: reservation.business,
          };
        });
        setShowAddReservationForm(RESERVATION_PAGE_TYPE.updateReservationForm);
      },
    };
    threeDotsOptions.push(treeDotsUpdateOption);
  }

  const getZoneById = (zoneId) => {
    const zone = zones.find((zone) => zone.id === zoneId);
    if (!zone) {
      return null;
    }
    return zone.name;
  };

  const handleGoToMenu = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${business?.id}${ROUTE_NAME.menu}`
    );
  };

  const handleOnConfirm = (e) => {
    e.stopPropagation();
    executeDeleteReservation(reservation.business.id, reservation.id);
    setOpenSlideConfirm(false);
  };

  const handleOnCancel = (e) => {
    e.stopPropagation();
    setOpenSlideConfirm(false);
  };

  const { startTime, endTime } = formatReservationTimeRange(
    reservation.startDateTime,
    reservation.endDateTime
  );

  const reservationDetail = (
    <div className="ReservationInfoMoreDetail" ref={OrderDetailRef}>
      <div onClick={handleGoToMenu} style={{ cursor: "pointer" }}>
        <div className="IconWrapper">
          <MenuIcon />
        </div>
        <span>{t("reservation.seeMenu")}</span>
      </div>
      {reservation.business.contact.phone.value && (
        <div>
          <div className="IconWrapper">
            <PhoneIcon />
          </div>
          <span>{reservation.business.contact.phone.value}</span>
        </div>
      )}
      {reservation.business.address.city && (
        <div>
          <div className="IconWrapper">
            <LocationIcon />
          </div>
          <span>
            {" "}
            {`${reservation.business.address.city || ""} ${
              reservation.business.address.city
                ? t("inputs.city").toLowerCase()
                : ""
            } ${
              reservation.business.address.city &&
              reservation.business.address.street
                ? ","
                : ""
            } ${reservation.business.address.street || ""}`}
          </span>
        </div>
      )}
    </div>
  );

  const ReservationStatus = (status) => {
    switch (status) {
      case enums.reservationStatus.pending:
        return (
          <div className="ReservationInfoStatusWrapper">
            <span className="ReservationInfoStatusPending">
              {t("reservation.pending")}
            </span>
            <PendingIcon />
          </div>
        );
      case enums.reservationStatus.confirmed:
        return (
          <div className="ReservationInfoStatusWrapper">
            <span className="ReservationInfoStatusConfirmed">
              {t("reservation.confirmed")}
            </span>
            <ConfirmedIcon />
          </div>
        );
      case enums.reservationStatus.denied:
        return (
          <div className="ReservationInfoStatusWrapper">
            <span className="ReservationInfoStatusDenied">
              {t("reservation.denied")}
            </span>
            <DeniedIcon />
          </div>
        );
      default:
        return null;
    }
  };

  const staticBoxHeight = 260;

  return (
    <div
      className="ReservationInfo"
      style={{
        height: showReservationDetail
          ? `calc(${staticBoxHeight}px + ${reservationDetailsHeight}px)`
          : `${staticBoxHeight}px`,
      }}
    >
      {isDeleteReservationLoading && (
        <div className="ReservationInfoMoreDetailLoading">
          <Spinner />
        </div>
      )}
      <div className="ReservationInfoHeader">
        <div className="ReservationInfoBusiness">
          <div className="ReservationInfoBusinessImage">
            <ImageWithPlaceholder
              imageSource={createDOBucketName(reservation.business.images.logo)}
              placeholder={IMAGE_ITEM_PLACEHOLDER}
              alt="logo"
            />
          </div>
          <h3>{reservation.business.name}</h3>
        </div>
        <div>
          <ThreeDots
            options={threeDotsOptions}
            className="ReservationInfoHeaderThreeDots"
          />
        </div>
      </div>
      <div className="ReservationInfoStatus">
        {ReservationStatus(reservation.status)}
      </div>
      <div className="ReservationInfoDetail">
        <div className="ReservationInfoDetailDate">
          <div>
            <CalendarIcon className="ReservationInfoDetailCalendarIcon" />
            <span>{formatReservationDate(reservation.startDateTime)}</span>
          </div>
          <div>
            <GuestCountIcon className="ReservationInfoDetailCalendarIcon" />
            <span>
              {t("guests.guest")} ({reservation.guestsCount})
            </span>
          </div>
        </div>
        <div className="ReservationInfoDetailGuest">
          <div>
            <ClockIcon className="ReservationInfoDetailClockIcon" />
            <span>
              {`${startTime} - ${endTime.includes(":") ? endTime : t(endTime)}`}
            </span>
          </div>
          <div>
            <TableIcon
              style={{ marginLeft: "2" }}
              className="ReservationInfoDetailCalendarIcon"
            />
            {reservation?.table?.name ? (
              <div className="ReservationTableAndZoneName">
                <h6>{reservation?.table?.name}</h6>
                <h5>{"("}</h5>
                <h6>{getZoneById(reservation.table?.zone)}</h6>
                <h5>{")"}</h5>
              </div>
            ) : (
              <span>{t("reservation.tableNotFound")}</span>
            )}
          </div>
        </div>
      </div>
      <div
        className="ReservationsDetailsButton"
        onClick={() => {
          setShowReservationDetail(!showReservationDetail);
        }}
      >
        <p>
          {showReservationDetail
            ? t("reservation.showLess")
            : t("reservation.showMore")}
        </p>
        <ArrowIcon
          className={showReservationDetail ? "RotateArrowIcon" : undefined}
        />
      </div>
      {reservationDetail}
      <Confirm
        title={t("modal.deleteModalTitle")}
        description={t("modal.deleteModalDescription")}
        mainElementRefConfirm={mainElementRefConfirm}
        openSlide={openSlideConfirm}
        onCancel={(e) => handleOnCancel(e)}
        onConfirm={(e) => handleOnConfirm(e)}
      />
    </div>
  );
};

ReservationInfo.propTypes = {
  reservation: PropTypes.object,
  setGuestReservations: PropTypes.func,
  setShowAddReservationForm: PropTypes.func,
  setFormData: PropTypes.func,
};

export default ReservationInfo;
