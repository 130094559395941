import React from "react";
import PropTypes from "prop-types";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import { createDOBucketName } from "utils/DO-Spaces";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import { useTranslation } from "react-i18next";
import { RESERVATION_ACTION_TYPE } from "pages/client/reservation/Reservation";
import CTAButton, {
  ENUMS as ENUMS_CTA_BUTTON,
} from "components/buttons/cta-button/CTAButton";
import "./ReservationFinalInfo.scss";

const ReservationFinalInfo = ({ formData, onSubmit, isLoading, type }) => {
  const { t } = useTranslation();

  return (
    <div className="ReservationFinalInfo">
      <div className="ReservationFinalInfoPlace">
        <h3>{t("reservation.place")}</h3>
        <div>
          <ImageWithPlaceholder
            imageSource={createDOBucketName(formData.business.images.logo)}
            placeholder={IMAGE_ITEM_PLACEHOLDER}
            alt="logo"
            className="ReservationBusinessSelectionLogo"
          />
          <h4>{formData.business.name}</h4>
        </div>
      </div>
      <div className="ReservationFinalInfoContact">
        <h3>{t("reservation.contact")}</h3>
        <p>
          {t("reservation.reserveeName")}: <span>{formData.guestName}</span>
        </p>
        <p>
          {t("inputs.phoneNumber")}: <span>{formData.guestPhone}</span>
        </p>
      </div>
      <div className="ReservationFinalInfoContact">
        <h3>{t("reservation.reservationInfo")}</h3>
        <p>
          {t("inputs.numberOfGuest")}: <span>{formData.guestsCount}</span>
        </p>
        <p>
          {t("reservation.date")}: <span>{formData.date}</span>
        </p>
        <p>
          {t("reservation.time")}: <span>{formData.time}</span>
        </p>
        {formData.reservationDuration.trim().length > 0 && (
          <p>
            {t("inputs.duration")}: <span>{formData.reservationDuration}</span>
          </p>
        )}
        {formData.guestComment.trim().length > 0 && (
          <p>
            {t("inputs.commentText")}: <span>{formData.guestComment}</span>
          </p>
        )}
      </div>
      <CTAButton
        type={ENUMS_CTA_BUTTON.types.TYPE_N}
        onClick={onSubmit}
        name={
          type === RESERVATION_ACTION_TYPE.create
            ? t("buttons.confirm")
            : t("buttons.saveChanges")
        }
        className="ReservationSubmitButton"
        isLoading={isLoading}
        disabled={isLoading}
      />
    </div>
  );
};

ReservationFinalInfo.propTypes = {
  selectedBusiness: PropTypes.object,
  formData: PropTypes.object,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  type: PropTypes.string,
};

export default ReservationFinalInfo;
