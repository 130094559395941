import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import useAsync from "utils/hooks/useAsync";
import GuestPageHeader from "components/elements/guest-page-header/GuestPageHeader";
import ReservationBusinessSelection from "./business-selection-form/ReservationBusinessSelection";
import { getAllBusinesses } from "utils/api/services/business";
import GuestInfoForm from "./guest-info-form/GuestInfoForm";
import { useTranslation } from "react-i18next";
import ReservationFinalInfo from "./reservation-final-info/ReservationFinalInfo";
import {
  createReservationGuest,
  updateReservationGuest,
} from "utils/api/services/reservation";
import { STORE_NAMES } from "utils/constants/redux";
import { RESERVATION_ACTION_TYPE } from "pages/client/reservation/Reservation";

import "./GuestReservationForm.scss";

export const RESERVATION_STEP_TYPES = {
  businessSelection: {
    type: "business_selection",
    title: "reservation.chooseRestaurant",
    goBackUrl: "businessSelection",
  },
  reservationForm: {
    type: "reservation_form",
    title: "reservation.reservation",
    goBackUrl: "businessSelection",
  },
  reservationFinalDetail: {
    type: "reservation_final_detail",
    title: "reservation.reservation",
    goBackUrl: "reservationForm",
  },
};

const GuestReservationForm = ({
  setShowAddReservationForm,
  executeGetGuestReservation,
  setFormData,
  formData,
  type,
  initialFormData,
}) => {
  const { t } = useTranslation();
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  // const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [activeStep, setActiveStep] = useState(
    type === RESERVATION_ACTION_TYPE.create
      ? RESERVATION_STEP_TYPES.businessSelection
      : RESERVATION_STEP_TYPES.reservationForm
  );

  const {
    execute: executeCreateReservationGuest,
    loading: isCreateReservationLoading,
  } = useAsync(createReservationGuest, {
    onSuccess: async () => {
      await executeGetGuestReservation(guestId);
      setShowAddReservationForm(false);
      setFormData(initialFormData);
    },
    onError: () => {},
  });

  const {
    execute: executeUpdateReservationGuest,
    loading: isUpdateReservationLoading,
  } = useAsync(updateReservationGuest, {
    onSuccess: async () => {
      await executeGetGuestReservation(guestId);
      setShowAddReservationForm(false);
      setFormData(initialFormData);
    },
    onError: () => {},
  });

  const {
    execute: executeGetAllBusiness,
    result: allBusinesses,
    loading: isBusinessesLoading,
  } = useAsync(getAllBusinesses);

  useEffect(() => {
    executeGetAllBusiness();
    return () => setFormData(initialFormData);
  }, []);

  const handleGoBack = () => {
    if (activeStep.type === RESERVATION_STEP_TYPES.businessSelection.type) {
      return setShowAddReservationForm(false);
    } else if (
      activeStep.type === RESERVATION_STEP_TYPES.reservationForm.type &&
      type === RESERVATION_ACTION_TYPE.update
    ) {
      return setShowAddReservationForm(false);
    }
    setActiveStep(RESERVATION_STEP_TYPES[activeStep.goBackUrl]);
  };

  const handleCreateReservation = () => {
    const reservationData = {
      guestId: formData.guestId,
      guestName: formData.guestName,
      guestPhone: formData.guestPhone.replace(/[\s()+-]/g, ""),
      guestComment: formData.guestComment,
      guestsCount: formData.guestsCount,
      startDateTime: formData.startDateTime,
      endDateTime: formData.endDateTime,
    };
    if (type === RESERVATION_ACTION_TYPE.create) {
      executeCreateReservationGuest(formData.business.id, reservationData);
    } else {
      delete reservationData.guestId;
      executeUpdateReservationGuest(
        formData.business.id,
        formData.reservationId,
        reservationData
      );
    }
  };

  const reservationStepper = (formType) => {
    switch (formType) {
      case RESERVATION_STEP_TYPES.businessSelection.type:
        return (
          <ReservationBusinessSelection
            businesses={allBusinesses}
            setActiveStep={setActiveStep}
            setFormData={setFormData}
            formData={formData}
            isBusinessesLoading={isBusinessesLoading}
          />
        );
      case RESERVATION_STEP_TYPES.reservationForm.type:
        return (
          <GuestInfoForm
            setActiveStep={setActiveStep}
            setFormData={setFormData}
            formData={formData}
            type={type}
          />
        );
      default:
        return (
          <ReservationFinalInfo
            formData={formData}
            onSubmit={handleCreateReservation}
            isLoading={isCreateReservationLoading || isUpdateReservationLoading}
            type={type}
          />
        );
    }
  };

  return (
    <div className="GuestReservationForm">
      <GuestPageHeader
        pageTitle={t(activeStep.title)}
        onGoBack={handleGoBack}
        showBurgerMenu={false}
      />
      {reservationStepper(activeStep.type)}
    </div>
  );
};

GuestReservationForm.propTypes = {
  setShowAddReservationForm: PropTypes.func,
  executeGetGuestReservation: PropTypes.func,
  setFormData: PropTypes.func,
  formData: PropTypes.object,
  type: PropTypes.string,
  initialFormData: PropTypes.object,
};

export default GuestReservationForm;
