import React, { useState } from "react";
import AdminNavbarController from "components/admin-navbar-controller/AdminNavbarController";
import AdminSidebar from "./AdminSidebar";
import useWindowSize, { BREAKPOINT_NAMES } from "utils/hooks/useWindowSize";
import { ROUTE_NAME } from "utils/constants/routes";
import { useNavigate } from "react-router-dom";
import { ReactComponent as HamburgerIcon } from "assets/icons/menu/HamburgerMenu.svg";

const AdminSidebarController = () => {
  const { isScreenSize } = useWindowSize();
  const isTabletScreen = isScreenSize(BREAKPOINT_NAMES.tablet);
  const initialNavbarState = !isTabletScreen;
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const navigate = useNavigate();

  const handleRouteClick = (route, index) => {
    setActiveIndex(index);
    if (route?.subRoutes?.length > 0) {
      setIsNavbarOpen(true);
      navigate(`${ROUTE_NAME.admin}${route.subRoutes[0].path}`);
    } else {
      navigate(`${ROUTE_NAME.admin}${route.path}`);
    }
    if (isTabletScreen) {
      setIsNavbarOpen(false);
    }
  };

  const hamburgerMenu = (
    <div
      className={`HamburgerButtonContainer ${isNavbarOpen ? "isHidden" : ""}`}
      onClick={() => setIsNavbarOpen(!isNavbarOpen)}
    >
      <HamburgerIcon />
    </div>
  );

  return (
    <AdminNavbarController
      isNavbarOpen={isNavbarOpen}
      setIsNavbarOpen={setIsNavbarOpen}
      isTabletScreen={isTabletScreen}
      hamburgerMenu={hamburgerMenu}
    >
      <AdminSidebar
        isNavbarOpen={isNavbarOpen}
        setIsNavbarOpen={setIsNavbarOpen}
        onRouteClick={handleRouteClick}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        isMobile={isTabletScreen}
      />
    </AdminNavbarController>
  );
};

export default AdminSidebarController;
