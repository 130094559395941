import React, { useEffect, useState } from "react";
import useAsync from "utils/hooks/useAsync";
import { useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";
import { useTranslation } from "react-i18next";
import { getGuestReservations } from "utils/api/services/reservation";
import ReservationInfo from "components/reservation-guest/reservation-info/ReservationInfo";
import GuestPageHeader from "components/elements/guest-page-header/GuestPageHeader";
import { ROUTE_NAME } from "utils/constants/routes";
import { useNavigate } from "react-router-dom";
import AddNewButton, {
  ENUMS as ADD_NEW_BUTTON_ENUMS,
} from "components/admin/buttons/add-new-button/AddNewButton";
import GuestReservationForm from "components/reservation-guest/guest-reservation-form/GuestReservationForm";
import Spinner from "components/elements/spinner/Spinner";
import EmptyState from "components/admin/empty-state/EmptyState";
import NoReservation from "assets/icons/admin-dashboard/ReservationEmptyState.svg";

import "./Reservation.scss";

export const RESERVATION_ACTION_TYPE = {
  create: "create",
  update: "update",
};

export const RESERVATION_PAGE_TYPE = {
  showReservationList: {
    value: false,
  },
  createReservationForm: {
    value: true,
    type: RESERVATION_ACTION_TYPE.create,
  },
  updateReservationForm: {
    value: true,
    type: RESERVATION_ACTION_TYPE.update,
  },
};

const Reservation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showAddReservationForm, setShowAddReservationForm] = useState(
    RESERVATION_PAGE_TYPE.showReservationList
  );
  const guest = useSelector((state) => state[STORE_NAMES.guest]);
  const { businessId } = useSelector((state) => state[STORE_NAMES.qrScan]);
  const [guestReservations, setGuestReservations] = useState([]);

  const initialFormData = {
    reservationId: null,
    tableId: null,
    guestId: guest.id,
    guestName: "",
    guestPhone: "",
    guestComment: "",
    guestsCount: 0,
    startDateTime: "",
    endDateTime: "",
    date: "",
    time: "",
    reservationDuration: "",
    business: null,
  };
  const [formData, setFormData] = useState(initialFormData);

  const {
    execute: executeGetGuestReservation,
    loading: isGetReservationLoading,
  } = useAsync(getGuestReservations, {
    onSuccess: (res) => setGuestReservations(res.data),
  });

  useEffect(() => {
    executeGetGuestReservation(guest.id);
  }, []);

  const handleGoBack = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`
    );
  };

  if (isGetReservationLoading) {
    return (
      <div className="GuestReservationLoadingWrapper">
        <Spinner className="GuestReservationLoading" />
      </div>
    );
  }

  return (
    <div className="ClientReservation">
      {!showAddReservationForm.value ? (
        <div className="GuestReservations">
          <GuestPageHeader
            pageTitle={t("navbarRoutes.reservation")}
            onGoBack={handleGoBack}
          />
          <div className="AddNewReservation">
            <h3>
              {t("reservation.reservations")} ({guestReservations?.length})
            </h3>
            <AddNewButton
              onClick={() =>
                setShowAddReservationForm(
                  RESERVATION_PAGE_TYPE.createReservationForm
                )
              }
              type={ADD_NEW_BUTTON_ENUMS.types.TYPE_F}
              label={t("buttons.add")}
            />
          </div>
          {guestReservations?.map((reservation) => (
            <ReservationInfo
              key={reservation.id}
              reservation={reservation}
              setGuestReservations={setGuestReservations}
              setShowAddReservationForm={setShowAddReservationForm}
              setFormData={setFormData}
            />
          ))}
          {guestReservations.length === 0 && (
            <EmptyState
              icon={NoReservation}
              description={t("emptyStates.noReservationDescription")}
              isAdmin={false}
              title={t("emptyStates.noReservations")}
            />
          )}
        </div>
      ) : (
        <GuestReservationForm
          setShowAddReservationForm={setShowAddReservationForm}
          executeGetGuestReservation={executeGetGuestReservation}
          setFormData={setFormData}
          formData={formData}
          type={showAddReservationForm.type}
          initialFormData={initialFormData}
        />
      )}
    </div>
  );
};

export default Reservation;
