import { createAsyncThunk } from "@reduxjs/toolkit";
import { STORE_NAMES } from "utils/constants/redux";
import {
  getAllReservations,
  getAllPendingReservations,
} from "utils/api/services/reservation";

export const getAllReservationsAsync = createAsyncThunk(
  `${STORE_NAMES.reservation}/getAllReservations`,
  async ({ businessId, startDate }) => {
    const response = await getAllReservations({ businessId, startDate });
    return response.data;
  }
);
export const getAllPendingReservationsAsync = createAsyncThunk(
  `${STORE_NAMES.reservation}/getAllPendingReservations`,
  async ({ businessId }) => {
    const response = await getAllPendingReservations(businessId);
    return response.data;
  }
);

export const createReservationAdminAsync = createAsyncThunk(
  `${STORE_NAMES.reservation}/createReservationAdmin`,
  async ({ businessId }) => {
    const response = await getAllReservations(businessId);
    return response.data;
  }
);
