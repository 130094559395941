import { AXIOS, AXIOS_NO_AUTH } from "utils/api/axios";
import { API_PATH_PARAMS } from "utils/constants/api";

export const createGuest = async () =>
  AXIOS_NO_AUTH.post(`/${API_PATH_PARAMS.users}/${API_PATH_PARAMS.guests}`);

export const updateGuestInfo = async (guestInfo, id) =>
  AXIOS.patch(
    `/${API_PATH_PARAMS.users}/${API_PATH_PARAMS.guests}/${id}`,
    guestInfo
  );
export const getAllGuests = async () =>
  AXIOS.get(`/${API_PATH_PARAMS.users}/${API_PATH_PARAMS.guests}`);
